@import "./_platform/include";
@import "./_theme/common";
@import "./_theme/libs";
@import "./_theme/uikit";

// $primary-color: #26B4FF;
$primary-color: #1E70CD;
$body-bg: #f5f5f5;

body {
  background: $body-bg;
}

.bg-body {
  background: $body-bg !important;
}

@include platform-common-theme($primary-color);
@include platform-libs-theme($primary-color);
@include platform-uikit-theme($primary-color);

// Navbar

@include platform-navbar-variant('.bg-navbar-theme', #fff, $color: $text-muted, $active-color: $body-color);

.layout-navbar {
  box-shadow: 0 1px 0 $border-color;
}

// Sidenav

@include platform-sidenav-variant('.bg-sidenav-theme', #2e323a, $color: #838790);

// Footer

@include platform-footer-variant('.bg-footer-theme', #fff, $color: $text-muted, $active-color: $body-color);
