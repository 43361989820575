.n-label {
  z-index: 1;
  &__list {
    padding-bottom: 24rem;
  }
  &__btn {
    margin: 12rem 0 8rem 72rem;
    width: 170rem;
  }
  &__card {
    &:hover {
      background: rgba(var(--colorRGB13), 0.2);
      .n-label__card__btns {
        display: block;
        display: flex;
      }
    }
    &__wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 0 72rem;
      padding: 10rem 0;
    }
    &__icon {
      padding-right: 8rem;
    }
    &__title {
      font-weight: 700;
      font-size: 15rem;
      line-height: 18rem;
      color: #FEFEFE;
      cursor: default;
    }
    &__btns {
      display: none;
      margin-left: auto;
      padding-right: 36rem;
      height: 21rem;
      div {
        margin-left: 8rem;
      }
    }
  }
}