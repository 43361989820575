@mixin el-workspace-widget-common {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  height: var(--wwidget-height);
  cursor: pointer;
}

.el-workspace-widget {
  @include el-workspace-widget-common;
  border: 1px solid var(--wwidget-border-color);
  background-color: var(--wwidget-background-color);
  box-shadow: 0px 3px 5px 0px var(--shadow-color);
  overflow: hidden;
  &.el-search-item-highlighted {
    .el-workspace-widget-header {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(255, 255, 255, 0.13);
      }
    }
  }
  &:hover {
    .el-workspace-widget-header-action-remove {
      opacity: 1;
    }
  }
}

.el-workspace-widget-header {
  width: 100%;
  height: var(--wwidget-header-height);
  padding: 12px;
  gap: 12px;
  display: flex;
  background-color: inherit;
  align-items: center;
  border-bottom: 1px solid #4e8de880;
  .el-store-item-channel-status {
    margin-left: 0;
  }
  .el-workspace-widget-header-action-remove {
    transition: var(--transition-default) opacity;
    opacity: 0;
  }
}

.el-workspace-widget-icon {
  display: flex;
  position: relative;
  width: 32px;
  height: 24px;
  overflow: hidden;
  border-radius: var(--border-radius-default);
  &:after {
    content: "";
    border: 1px solid #4e8de880;
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius-default);
  }
  &.medium {
    width: 48px;
    height: 36px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.el-workspace-widget-content {
  width: 100%;
  height: var(--wwidget-content-height);
  position: relative;
  &.withGradient:before {
    z-index: 3;
    // content: "";
    transition: all var(--transition-default);
    position: absolute;
    inset: -1% 0 0;
    background: linear-gradient(
      180deg,
      var(--wwidget-background-color) 1.64%,
      rgba(32, 59, 96, 0.15) 100%
    );
    inset: -1% 0 97.5% 0;
    opacity: 0;
  }
  &:hover:before {
  }
}

.el-workspace-widget-name {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
}

.el-workspace-widget-empty {
  @include el-workspace-widget-common;
  border: 1px dotted var(--wwidget-border-dotted-color);
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  transition: background-color var(--transition-default);
  cursor: pointer;
  &.activated {
    .el-workspace-widget-empty-wrapper {
      max-height: 66%;
      .el-workspace-widget-empty-icon {
        transform: scale(0.9);
      }
    }
    .el-workspace-widget-empty-buttons {
      opacity: 1;
      pointer-events: initial;
    }
  }
  &:hover {
    background-color: var(--wwidget-empty-hovered);
  }
}

.el-workspace-widget-empty-wrapper,
.el-workspace-widget-empty-buttons {
  width: 100%;
}

.el-workspace-widget-empty-wrapper {
  transition: max-height var(--transition-default);
  max-height: 30%;
  height: 100%;
}

.el-workspace-widget-empty-wrapper-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
}

.el-workspace-widget-empty-icon {
  width: 36px;
  border: 2px solid var(--text-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 50%;
  opacity: 0.5;
  transform: scale(1.25);
  transition: transform var(--transition-default),
    width var(--transition-default), height var(--transition-default);
}

.el-workspace-widget-empty-text {
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  color: var(--text-color);
  user-select: none;
}

.el-workspace-widget-empty-buttons {
  height: 50%;
  opacity: 0;
  pointer-events: none;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: absolute;
  bottom: 0;
  transition: opacity var(--transition-default);
  transition-delay: 0.05s;
  padding: 0 16px;
}

.el-workspace-widget-extra-space {
  padding: var(--gradient-height) 0 0;
  iframe {
    height: calc(
      100vh - var(--header-height, 45px) -
        (2 * var(--el-content-container-padding, 32px)) - var(--gradient-height)
    ) !important;
    width: 100%;
  }
}

.el-workspace-widget-extra-content {
  cursor: pointer;
  iframe {
    min-height: calc(var(--wwidget-height) - var(--wwidget-header-height));
  }
}

.el-workspace-widget-toolbar-outer {
  position: relative;
}

.el-workspace-widget-toolbar {
  position: absolute;
  transition: var(--transition-default);
  opacity: 1;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  &.hidden {
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
}

.el-workspace-widget-toolbar-action {
  max-width: var(--chat-toolbar-width);
  width: var(--chat-toolbar-width);
  cursor: pointer;
  .el-text,
  .el-icon {
    transition: var(--transition-default) all;
  }
  .el-text {
    color: var(--text-color-80);
  }
  .el-icon {
    opacity: 0.8;
  }
  &:hover {
    .el-icon {
      opacity: 0.9;
    }
    .el-text {
      color: var(--text-color);
    }
  }
}

.el-workspace-widget-toolbar-opened {
  width: var(--chat-opened-toolbar-width);
  max-width: var(--chat-opened-toolbar-width);
  min-width: var(--chat-opened-toolbar-width);
  overflow: hidden;
  opacity: 0;

  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: var(--transition-quick);
  animation-delay: var(--transition-default);
}
