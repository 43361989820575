.el-checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--el-checkbox-background-color);
  margin: 0;
  font: inherit;
  color: var(--el-checkbox-color);
  width: var(--el-checkbox-default-width);
  height: var(--el-checkbox-default-height);
  border: 2px solid var(--el-checkbox-color);
  border-radius: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
  &:before {
    content: "";
    width: calc(var(--el-checkbox-default-size) / 1.75);
    height: calc(var(--el-checkbox-default-size) / 1.75);
    transform: scale(0);
    transition: var(--transition-quick) transform ease-in-out;
    box-shadow: inset 1em 1em #f2f2f2;
    transform-origin: center;
    clip-path: polygon(
      16.84% 49.75%,
      4.58% 65.32%,
      39.13% 92.51%,
      99.05% 27.1%,
      84.14% 13.44%,
      36.62% 65.32%
    );
  }
  &:checked::before {
    transform: scale(1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:focus {
    outline: 2px solid var(--el-checkbox-outline-color);
    outline-offset: 2px;
  }
}
