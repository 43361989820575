.n-checkbox-switch {
  label {
    display: block;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10rem;
    > input {
      display: none;
    }
    
    span {
      display: flex;
      margin-left: 10rem;
      font-size: 15rem;
      line-height: 18rem;
      letter-spacing: 0.01em;
      color: #FEFEFE;
      position: relative;

    }
    
    i {
      display: inline-block;
      width: 38rem;
      height: 22rem;
      border-radius: 20rem;
      vertical-align: middle;
      transition: .25s .09s;
      position: relative;
      background: rgba(255, 255, 255, 0.5);
      &:after {
        content: " ";
        display: block;
        width: 14rem;
        height: 14rem;
        top: 4rem;
        left: 5rem;
        background: #fff;
        position: absolute;
        box-shadow: 0rem 1rem 6rem rgba(24, 28, 33, 0.09), 0rem 0rem 0rem 1rem rgba(24, 28, 33, 0.04);
        border-radius: 100rem;
        transition: .15s;
      }
      &:before {
        content: "";
        display: block;
        width: 8rem;
        height: 8rem;
        top: 7rem;
        right: 8rem;
        background-image: url('data:image/svg+xml,%3Csvg width="8rem" height="8rem" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M7.49219 1.21094L4.70312 4L7.49219 6.78906L6.78906 7.49219L4 4.70312L1.21094 7.49219L0.507812 6.78906L3.29688 4L0.507812 1.21094L1.21094 0.507812L4 3.29688L6.78906 0.507812L7.49219 1.21094Z" fill="%23181C21" fill-opacity="0.9"/%3E%3C/svg%3E%0A');
        position: absolute;
        // box-shadow: 0rem 1rem 6rem rgba(24, 28, 33, 0.09), 0rem 0rem 0rem 1rem rgba(24, 28, 33, 0.04);
        // border-radius: 100rem;
        // transition: .15s;
      }
    }
    
    // Checked-state
    > input:checked + i {
      background: var(--colorHEX5);
    }
    
    > input:checked + i:after {
      transform: translateX(15rem);
    }
    > input:checked + i::before {
      background-image: url('data:image/svg+xml,%3Csvg width="10rem" height="8rem" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M3.35938 5.96875L8.75 0.578125L9.5 1.30469L3.35938 7.42188L0.5 4.5625L1.25 3.83594L3.35938 5.96875Z" fill="%23181C21" fill-opacity="0.9"/%3E%3C/svg%3E%0A');
      transform: translateX(-15rem);
    }
  }
  &__input {
    margin: 0 8rem;
    position: absolute;
    top: 15rem;
    // padding: 14.5rem 40rem 14.5rem 18rem;
    input[type=text] {
      width: 56rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      box-sizing: border-box;
      border-radius: 5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8rem 10rem;
      // padding: 14.5rem 40rem 14.5rem 18rem;
      font-size: 15rem;
      line-height: 18rem;
      color: rgba(255, 255, 255, 0.7);
      &::placeholder {
        font-size: 15rem;
        line-height: 18rem;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0.5) 66%, rgba(255, 255, 255, 0.5));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: var(--colorHEX7);
      }
      &:hover {
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
        color: #FFFFFF;
        &::placeholder {
          color: #FFFFFF;
        }
      }
      &:focus {
        background: var(--colorHEX4);
        color: #FFFFFF;
      }
    }
  }
}

.n-checkbox-switch .n-checkbox-switch__input {
  top: -8rem;
}

.limit-message label span {
  position: relative;
  top: 8rem;
}