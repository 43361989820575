.el-stripe-form-wrapper {
  .StripeElement {
    height: 40rem;
    padding: 10rem 12rem;
    width: 550rem;
    color: #32325d;
    background-color: #ffffff;
    border: 1rem solid transparent;
    border-radius: 4px;

    // box-shadow: 0 1rem 3rem 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement .InputElement {
    position: absolute !important;
    top: 0;
  }
  .ElementsApp input {
    background-color: red !important;
    /* border: none; */
    display: block;
    font-family: sans-serif;
    font-weight: 600 !important;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 1em;
    line-height: 1.2em;
    height: 1.2em;
  }
}

.el-stripe-checkout-wrapper {
}

.el-stripe-card-wrapper {
  max-width: 640px;
  color: var(--text-color);
  font-size: 18px;
  width: 100%;
}

.el-stripe-card-content {
}

.el-stripe-card-title {
  display: -ms-flexbox;
  display: flex;
  line-height: 21rem;
}

.el-stripe-card-input_wrapper {
  width: 100%;
  .StripeElement {
    width: 100%;
    border-radius: var(--border-radius-default);
    height: var(--el-input-default-height);
    background-color: transparent;
    box-shadow: var(--input-box-shadow, var(--input-box-shadow-default));
    color: var(--text-color);
    iframe {
      .CardField {
        color: var(--text-color);
        opacity: 0.5;
      }
    }
  }
}

.StripeElement {
  height: 40rem;
  padding: 10rem 12rem;
  width: 550rem;
  color: #32325d;
  background-color: #ffffff;
  border: 1rem solid transparent;
  border-radius: 4px;
  transition: box-shadow 150ms ease;
}

.el-stripe-card-button {
}
