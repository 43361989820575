:root {
  --admin-text-primary-900: #101828;
  --admin-text-secondary-700: #344054;
  --admin-text-secondary-hover: #182230;
  --admin-text-tertiary-600: #475467;
  --admin-text-quaternary-500: #667085;
  --admin-text-error-primary-600: #D92D20;
  --admin-text-disabled: #667085;
  --admin-text-white: #fff;

  --admin-text-brand-secondary-700: #6941C6;

  --admin-border-primary: #D0D5DD;
  --admin-border-secondary: #EAECF0;
  --admin-border-brand: #7F56D9;
  --admin-border-disabled: #EAECF0;
  --admin-border-error: #FDA29B;

  --admin-background-primary: #ffffff;
  --admin-background-secondary: #F9FAFB;
  --admin-background-quaternary: #EAECF0;
  --admin-background-active: #F9FAFB;

  --admin-background-brand-primary: #F9F5FF;
  --admin-background-brand-secondary: #F4EBFF;

  --admin-background-primary-hover: #F9FAFB;
  --admin-background-disabled: #F2F4F7;
  --admin-background-disabled-subtle: #F9FAFB;

  --admin-foreground-brand-solid: #7F56D9;
  --admin-foreground-brand-solid-hover: #6941C6;

  --admin-foreground-brand-secondary-700: #7F56D9;

  --admin-foreground-secondary: #344054;
  --admin-foreground-tertiary: #475467;
  --admin-foreground-quaternary-500: #667085;
  --admin-foreground-quinary-400: #98A2B3;
  --admin-foreground-quinary-hover: #667085;
  --admin-foreground-disabled: var(--admin-foreground-quinary-400);
  --admin-foreground-error-secondary: #F04438;

  --admin-button-primary-fg: #ffffff;
  --admin-button-primary-bg: var(--admin-foreground-brand-secondary-700);
  --admin-button-primary-bg-hover: #6941C6;

  --admin-button-tertiary-fg: #6941C6;
  --admin-button-tertiary-fg-hover: #53389E;

  --admin-button-tertiary-fg-gray: #475467;
  --admin-button-tertiary-fg-gray-hover: #344054;

  --utility-gray-700: #344054;
  --utility-gray-600: #475467;
  --utility-gray-500: #667085;
  --utility-gray-200: #EAECF0;
  --utility-gray-50: #F9FAFB;

  --utility-success-700: #067647;
  --utility-success-600: #079455;
  --utility-success-500: #17B26A;
  --utility-success-200: #ABEFC6;
  --utility-success-50: #ECFDF3;

  --utility-error-700: #B42318;
  --utility-error-600: #D92D20;
  --utility-error-500: #F04438;
  --utility-error-200: #FECDCA;
  --utility-error-50: #FEF3F2;

  --admin-button-primary-border: var(--admin-foreground-brand-secondary-700);

  --admin-spacing-xs: 4px;
  --admin-spacing-sm: 6px;
  --admin-spacing-md: 8px;
  --admin-spacing-lg: 12px;
  --admin-spacing-xl: 16px;
  --admin-spacing-2xl: 16px;
  --admin-spacing-3xl: 24px;

  --admin-radius-sm: 6px;
  --admin-radius-md: 8px;
  --admin-radius-lg: 10px;
  --admin-radius-xl: 12px;
  --admin-radius-full: 9999px;

  --transition-duration-quick: 0.1s;
  --transition-duration-default: 0.2s;

  --z-index-modal: 10;
}