.el-modal-wrapper {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.el-modal-content {
  color: var(--text-color);
  padding: 6px 0;
  height: 100%;
}

.el-modal {
  position: absolute;
  border-radius: var(--border-radius-default);
  border: 1px solid #ffffff1a;
  overflow: hidden;
  background-color: #3764a4;
}
