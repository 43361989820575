.n-input {
  input[type=text], input[type="password"] {
    width: 100%;
    background: #FFFFFF;
    border: 1rem solid var(--colorHEX14);
    box-sizing: border-box;
    border-radius: 24rem;
    padding: 12rem 16rem;
    color: var(--colorHEX5);
    font-size: 17rem;
    line-height: 20rem;
    float: left;
    margin: 10rem 0 10rem 0;
    &::placeholder {
      color: var(--colorHEX5);
      opacity: 0.5;
      font-size: 17rem;
      line-height: 20rem;
    }
    &:hover {
      border: 2rem solid var(--colorHEX14);
      padding: 11rem 15rem;
    }
    &:focus {      
      border: 2rem solid var(--colorHEX14);
      padding: 11rem 15rem;
    }
  }
  &__error-message {
    font-weight: 500;
    font-size: 12rem;
    line-height: 16rem;
    display: flex;
    align-items: flex-start;
    text-align: start!important;
    color: #E30C0C;
    display: none;
  }
}

.error .n-input__error-message {
  display: block;
}

.error  {
  input {
    border: 1rem solid #E30C0C!important;
    &:hover {
      border: 2rem solid #E30C0C!important;
      padding: 12rem 12rem;
    }
    &:focus {      
      border: 2rem solid #E30C0C!important;
      padding: 12rem 12rem;
    }
  }
}