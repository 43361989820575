.n-header {
  height: 60rem;
  width: 100%;
  background-color: var(--colorHEX9);
  min-width: 1000rem;

  &__container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
                    180deg,
                    rgba(17, 17, 17, 0) 0%,
                    rgba(17, 17, 17, 0.2) 100%
    );
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7rem 40rem 6rem 40rem;
  }

  &__logo {
    margin-right: 30rem;
    display: flex;
    align-items: center;

    &__avatar {
      vertical-align: middle;
      width: 60rem;
      height: 45rem;
      border-radius: 4px;

      &__skeleton {
        background-color: #4f46e5;
        animation: pulse 2s infinite ease-in-out;
      }
    }
  }

  &__nav {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;

    &__links {
      display: flex;

      .n-button-header {
        margin: 0 8rem;

        &--people {
          margin-right: 24rem;
        }
      }
    }

    &__profile {
      margin-left: auto;
      display: flex;

      div {
        margin-left: 8rem;
      }
    }
  }
}

// btn header
.n-btn-svg-icon {
  margin-right: 8rem;
}

.n-btn1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 8rem 20rem;
  background: rgba(255, 255, 255, 0.2);
  outline: 1rem solid rgba(var(--colorRGB2), 0.3);
  border-radius: 4px;
  font-size: 20rem;
  line-height: 29rem;
  color: #ffffff;

  &__icon {
    width: 24rem;
    height: 24rem;
  }

  &__title {
    display: flex;
    justify-content: space-around;

    &__text {
      margin: 0 20rem;
    }

    &__icon-close {
      display: none;
    }

    &__icon-edit {
      display: none;
    }
  }
}

.n-btn1-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 8rem 20rem;
  background: rgba(var(--colorRGB2), 0.3);
  border: 1px solid #0f1b2d;
  border-radius: 4px;
  font-size: 20rem;
  line-height: 29rem;
  color: #ffffff;

  &__title {
    display: flex;
    justify-content: space-around;

    &__text {
      margin: 0 20rem;
    }

    &__icon-close {
      display: block;

      svg {
        fill: #fff !important;
      }

      &:hover {
        svg {
          fill: #e30c0c !important;
        }
      }
    }

    &__icon-edit {
      display: block;
    }
  }
}

// .n-btn1:hover {
//   // background: rgba(255, 255, 255, .3);
// }

.n-btn2 {
  display: inline-block;
  background-color: #fff;
  border-radius: 3px;
  color: #000;
  padding: 8rem 16rem;
  font-size: 16rem;
  cursor: pointer;
  margin: 0 5rem;
}

.n-btn2:hover {
  background-color: rgb(124, 124, 124);
}