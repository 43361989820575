.el-button {
  cursor: pointer;
  text-align: initial;
  color: var(--text-color);
  font-weight: 400;
  font-size: var(--default-font-size);
  gap: var(--el-button-gap, 12px);
  border: 0;
  user-select: none;
  background: transparent;
  height: var(--el-button-default-height);
  min-height: var(--el-button-default-height);
  line-height: var(--el-button-default-height);
  border-radius: var(--button-border-radius);
  .el-button-center,
  .el-button-left,
  .el-button-right {
    line-height: inherit;
    vertical-align: middle;
  }
  &.inlineFlex {
    display: inline-flex;
    align-items: center;

    .el-button-center,
    .el-button-left,
    .el-button-right {
      display: inherit;
      align-items: inherit;
    }

    .el-button-center {
      flex: 1;
    }
  }

  &.inlineFlexCenter {
    align-items: center;
    justify-content: center;
  }

  &.widthFull {
    width: 100%;
  }

  &.heightFull {
    height: 100%;
  }

  &.aside {
    min-height: inherit;
    transition: background-color var(--transition-default);
    line-height: 24px;
    font-size: 14px;
    padding: 0px 8px;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;

    &.hovered {
      transition: gap var(--transition-long);

      &:hover {
        gap: 6px;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  &.asideTitle {
    font-size: 16px;
  }

  &.themePrimary {
    text-align: center;
    color: var(--text-color);
    background: #4279c6;
    max-width: 350px;
    transition: filter var(--transition-default);
    padding: 0 12px;
    box-shadow: 0px 2px 4px 0px rgba(4, 7, 11, 0.25);
    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(1.2);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.33;
    }
  }

  &.themeOutline {
    text-align: center;
    color: var(--text-color);
    box-shadow: 0px 0px 0px 1px var(--text-color) inset;
    background-color: rgba(255, 255, 255, 0);
    position: relative;
    z-index: 1;
    padding: 0 12px;

    &:before {
      content: "";
      transition: background-color var(--transition-default);
      position: absolute;
      inset: 0;
      background-color: rgba(255, 255, 255, 0);
    }

    &:hover:before {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:active:before {
      background-color: rgba(255, 255, 255, 0.25);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.66;
    }
  }

  &.themeTab {
    height: auto;
    padding: 4px 12px 4px 12px;
    background-color: #4279c64d;
    box-shadow: 0px 2px 4px 0px #04070b40;
    text-align: center;
    transition: background-color var(--transition-quick);
    position: relative;

    &:before {
      content: "";
      transition: opacity var(--transition-default);
      position: absolute;
      inset: 0;
      opacity: 0;
      background-color: #ffffff1a;
    }

    &:hover:before {
      opacity: 1;
    }

    &.active {
      background-color: transparent;
      box-shadow: inset 0px 0px 0px 1px #264571;

      &:hover:before {
        opacity: 0;
      }
    }
  }

  &.asIconAction {
    opacity: 0.75;
    transition: opacity var(--transition-default);

    &:hover {
      opacity: 1;
    }
  }
}

.el-button-left {
  margin-right: 4px;
}

.el-button-right {
}
