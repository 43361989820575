.n-public-channels {
  &__search {
    padding: 32rem 36rem 16rem 36rem;
    position: relative;
    input[type=text] {
      width: 100%;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      box-sizing: border-box;
      border-radius: 5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12rem 12rem 12rem 18rem;
      font-size: 16rem;
      line-height: 19rem;
      color: #FFFFFF;
      &:placeholder-shown + .n-public-channels__search__reset {
        opacity: 0;
        pointer-events: none;
      }
      &::placeholder {
        font-size: 16rem;
        line-height: 19rem;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0.5) 66%, rgba(255, 255, 255, 0.5));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: var(--colorHEX7);
      }
      &:hover {
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
        color: #FFFFFF;
        &::placeholder {
          color: #FFFFFF;
        }
      }
      &:focus {
        background: var(--colorHEX4);
        color: #FFFFFF;
      }
    }
    &__reset {
      // cursor: pointer;
      position: absolute;
      border: none;
      display: block;
      width: 15rem;
      height: 15rem;
      line-height: 16rem;
      font-size: 12rem;
      border-radius: 50%;
      top: 15rem;
      bottom: 0;
      right: 51rem;
      margin: auto;
      // background: #ddd;
      padding: 0;
      outline: none;
      cursor: pointer;
      transition: .1s;
      svg {
        top: 0rem !important;
        bottom: 0 !important;
        right: 0rem !important;
        fill: var(--colorHEX10);
        stroke: var(--colorHEX10) !important;
      }
    }
    svg {
      position: absolute;
      right: 50rem;
      top: 46rem;
      pointer-events: none;
      stroke: var(--colorHEX7);
    }
  }
  &__nav {
    display: flex;
    // justify-content :flex-start;
    padding: 16rem 36rem 16rem 36rem;
    &__sort-btn {
      width: 30rem;
      height: 30rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;
      padding: 3rem;
      cursor: pointer;
      svg {
        fill: var(--colorHEX13);
      }
      &:hover {
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
      }
    }
    &__sort-dropdown {
      margin: 0 10rem;
      padding: 0 12rem;
      color: var(--colorHEX13);
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;

      font-size: 14rem;
      line-height: 21rem;

      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;
      svg {
        margin-left: 4rem;
        fill: var(--colorHEX13);
      }
      &:hover {
        color: #FFFFFF;
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
        svg {
          fill: #FFFFFF;
        }
      }
    }
    &__create {
      margin-left: auto;
    }
  }
  &__accordion-list {
    height: calc(100vh - 437rem);
    overflow: auto;
  }
  &__accordion {
    &__header {
      display: flex;
      align-items: center;
      padding: 8rem 36rem;
      cursor: pointer;
      &:hover {
        background: rgba(var(--colorRGB13), 0.2);
      }
      svg {
        fill: var(--colorHEX13)
        
      }
      &.active {
        background: rgba(var(--colorRGB13), 0.1);
        &:hover {
          background: rgba(var(--colorRGB13), 0.2);
        }
        svg {
          fill: var(--colorHEX13)
        }
      }
      &__title {
        margin: 0rem 12rem;
        font-weight: 700;
        font-size: 15rem;
        line-height: 20rem;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
                          linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
        
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        &.active {
          background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
                            linear-gradient(0deg, var(--colorHEX14), var(--colorHEX14));
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; 
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
        }
      }
      &__icon-second {
        transition: all 0.4s ease-in-out;
        transform: rotate(0deg);
        margin-left: auto;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
    &__body {
      background: rgba(var(--colorRGB13), 0.1);
    }
  }
  // card
  &__card {

    &__wrapper {
      display: flex;
      align-items: center;
      padding: 12rem 36rem;
      &:hover {
        background: rgba(var(--colorRGB13), 0.2);
        .n-public-channels__card__info {
          display: none;
        }
        .n-public-channels__card__btns {
          display: block;
        }
      }
    }
    &__image {
      img {
        border: 1rem solid var(--colorHEX13);
        border-radius: 8rem;
      }
    }
    &__content {
      padding: 0rem 16rem;
      // width: 100%;
      &__title {
        font-weight: 700;
        font-size: 15rem;
        line-height: 18rem;
        color: #FEFEFE;
        cursor: pointer;
      }
      &__descriptions {
        margin-top: 6rem;
        height: 26rem;
        overflow: auto;
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
      }
    }
    &__info {
      margin-left: auto;
      margin-top: 6rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 20rem;
      white-space: nowrap;
      &__status-list {
        display: flex;
        align-items: center;
        text-align: center;
        &__status {
          margin-left: 4rem;
          font-weight: 700;
          font-size: 11rem;
          line-height: 13rem;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
          svg {
            margin: 4rem;
          }
        }
      }
      &__author {
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
      }
    }
    &__btns {
      display: none;
      margin-left: auto;
      &__wrapper {
        display: flex;
        div {
          margin-left: 8rem;
        }
      }
    }
    &__border {
      margin: 0 36rem;
      border-bottom: 1rem solid var(--colorHEX13);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      opacity: 0.2;
    }
  }
}
