:root {
  --default-background-color: #15263e;
  --background-color: var(--default-background-color);
  --aside-background-color: #3764a4;
  --aside-width: 240px;
  --aside-width-closed: 120px;
  --aside-logo-width: 160px;
  --transition-quick: 0.1s;
  --transition-default: 0.2s;
  --transition-long: 0.3s;
  --animation-quick: 0.1s;
  --animation-default: 0.2s;
  --animation-long: 0.3s;
  --border-radius-default: 3px;
  --border-radius-less: 2px;
  --text-color: white;
  --text-color-80: #ffffffcc;
  --text-color-70: #ffffffb3;
  --text-color-50: #ffffff80;
  --text-color-25: #ffffff40;
  --secondary-text-color: #ffffffa8;
  --green-color: #00ff00;
  --red-color: #ff3a3a;
  --red-color-muffled: #eb5757;
  --scroll-thumb-width: 8px;

  --default-font-size: 18px;
  --button-border-radius: 2px;
  --el-split-screen-schema: "";
  --shadow-color: #04070b40;
  --gradient-height: 16px;

  --wwidget-background-color: #203b60;
  --wwidget-min-width: 350px;
  --wwidget-max-width: 406px;
  --wwidget-height: 300px;
  --wwidget-header-height: 48px;
  --wwidget-content-height: calc(
    var(--wwidget-height) - var(--wwidget-header-height)
  );
  --wwidget-border-color: #5498f91a;
  --wwidget-border-dotted-color: #5498f980;
  --wwidget-empty-hovered: #5498f980;

  --chat-color: var(--text-color);
  --chat-message-min-width-px: 200px;
  --chat-message-min-width-percent: 90%;
  --chat-message-max-width-px: 960px;
  --chat-message-max-width-percent: 80%;
  --chat-message-receiver-color: var(--text-color);
  --chat-message-sender-color: #ffffffcc;
  --chat-message-author-color: #2372e9;
  --chat-message-channel-color: #4279c6;
  --chat-message-time-color: #ffffff40;
  --chat-spaces-width: 360px;
  --chat-spaces-collapsed-width: 132px;
  --chat-spaces-last-message-color: #ffffff99;
  --chat-counter-height: 16px;
  --chat-counter-color: #b3261e;
  --chat-send-min-height: 46px;
  --chat-send-icon-color: #4e8de8;
  --chat-time-separator-color: var(--chat-message-time-color);
  --chat-toolbar-width: 66px;
  --chat-opened-toolbar-width: 360px;

  --input-border-color: #4e8de8;
  --input-box-shadow-default: inset 0px 0px 0px 1px var(--input-border-color);
  --input-box-shadow-new-ws: 0px 4px 4px 0px #00000040 inset;
  --input-padding: 12px;
  --input-side-padding: 44px;
  --input-padding-left: var(--input-side-padding);
  --input-padding-right: var(--input-side-padding);
  --el-input-aside-item-max-height: var(--el-aside-item-min-height);

  --el-aside-item-min-height: 54px;

  --el-search-max-height: 80px;

  --el-emoji-left-right-padding: 8px;
  --el-emoji-input-padding: 6px;
  --el-emoji-background-color: var(--aside-background-color);
  --el-emoji-modal-width: 344px;
  --el-emoji-modal-height: 500px;
  --el-emoji-modal-bar-height: 30px;
  --el-emoji-modal-search-height: 30px;
  --el-emoji-anchors-height: var(--el-emoji-modal-bar-height);

  --store-item-logo-width: 80px;
  --store-item-logo-height: 60px;
  --store-background-color: #264571;
  --store-block-width: 360px;
  --store-block-height: 360px;
  --store-block-hovered: #5499f945;
  --store-space-collapsed-width: 90px;
  --store-details-width: 360px;
  --profile-edit-width: 360px;
  --store-item-height: 88px;
  --growth-color: var(--green-color);
  --decline-color: var(--red-color);

  --el-checkbox-default-size: 18px;
  --el-checkbox-default-height: var(--el-checkbox-default-size);
  --el-checkbox-default-width: var(--el-checkbox-default-size);
  --el-checkbox-color: #4e8de8;
  --el-checkbox-background-color: #0f1b2d80;
  --el-checkbox-outline-color: var(--el-checkbox-color);

  --el-radio-default-size: 18px;
  --el-radio-default-height: var(--el-radio-default-size);
  --el-radio-default-width: var(--el-radio-default-size);
  --el-radio-color: #4e8de8;
  --el-radio-background-color: #0f1b2d80;
  --el-radio-outline-color: var(--el-checkbox-color);

  --el-button-default-height: 44px;
  --el-input-default-height: 44px;

  --el-toggle-default-width: 38px;
  --el-toggle-default-height: 22px;
  --el-toggle-slider-default-width: 14px;
  --el-toggle-slider-default-height: 14px;
  --el-toggle-slider-default-offset: 4px;
  --el-toggle-default-background-color: #ffffff80;
  --el-toggle-default-slider-color: #e6e6e7;
  --el-toggle-checked-slider-color: #4279c6;

  --el-icon-source-color: rgba(217, 217, 217, 1);

  --hover-background-color: rgba(255, 255, 255, 0.05);
  --active-background-color: rgba(255, 255, 255, 0.1);
}
