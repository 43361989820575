@mixin platform-uikit-theme($background) {
  .ui-product-color.active {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $background !important;
  }
}

@mixin platform-uikit-material-theme($background) {
  .ui-product-color.active {
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px $background !important;
  }
}

@mixin platform-uikit-dark-theme($background) {
  .ui-product-color.active {
    box-shadow: 0 0 0 2px $body-bg, 0 0 0 4px $background !important;
  }
}
