.el-grid {
  display: grid;
  &.el-grid-gap {
    gap: var(--el-grid-gap);
  }
  &.el-grid-full {
    height: 100%;
    width: 100%;
  }
  &.el-grid-full-height {
    height: 100%;
  }
  &.el-grid-full-width {
    width: 100%;
  }
  &.el-grid-center {
    align-content: center;
    justify-content: center;
  }
  &.el-grid-align-center {
    align-content: center;
  }
  &.el-grid-justify-center {
    justify-content: center;
  }
  &.el-grid-template-areas {
    grid-template-areas: var(--el-grid-template-areas);
  }
  &.el-grid-template-rows {
    grid-template-rows: var(--el-grid-template-rows);
  }
  &.el-grid-template-columns {
    grid-template-columns: var(--el-grid-template-columns);
  }
  &.el-grid-area {
    grid-area: var(--el-grid-area);
  }
  &.el-grid-autoflow {
    grid-auto-flow: var(--el-grid-autoflow);
  }
}

.el-grid-item {
  &.el-grid-item-gap {
    gap: var(--el-grid-item-gap, 0);
  }
  &.el-grid-item-full {
    height: 100%;
    width: 100%;
  }
  &.el-grid-item-full-height {
    height: 100%;
  }
  &.el-grid-item-full-width {
    width: 100%;
  }

  &.el-grid-item-center-self {
    align-self: center;
    justify-self: center;
  }
  &.el-grid-item-align-self {
    align-self: center;
  }
  &.el-grid-item-justify-self {
    justify-self: center;
  }
  &.el-grid-item-area {
    grid-area: var(--el-grid-item-area);
  }
}
