.n-channels-menu {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    left: 0;
    background: rgba(var(--colorRGB5), 1);
    transition: 0.5s;
    height: calc(100vh - 120rem);
    top: 60rem;
    &__header {
        padding: 44rem 44rem 34rem 44rem;
        &__text {
            font-weight: 500;
            font-size: 36rem;
            line-height: 40rem;
            text-align: right;
            background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            cursor: default;
        }
    }
    &__tabs {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0 0 0 14rem;
        &__nav {
            position: relative;
            font-size: 24rem;
            line-height: 28rem;
            letter-spacing: 0.01em;
            background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            margin: 0 28rem;
            height: 43rem;
            cursor: pointer;
            &.active {
                font-weight: 700;
                font-size: 24rem;
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, #fff, #fff);
                background-size: 100%;
                background-repeat: repeat;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                &:after {
                    content: "";
                    width: 100%;
                    height: 3rem;
                    background: var(--colorHEX13);
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                    z-index: -1;
                    transition: all 0.3s ease 0s;
                }
            }
            &.disabled {
                color: rgba(255, 255, 255, 0.1);
                background-image: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
                background-size: 100%;
                background-repeat: repeat;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent; 
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                cursor: default;
            }
        }
    }
    &__hr-line {
        height: 1rem;
        // width: 100rem;
        background: var(--colorHEX13);
    }
    &__body {
        height: calc(101vh - 292rem);
        background: linear-gradient(
            180deg,
            rgba(var(--colorRGB13), 0.2) 0%,
            rgba(var(--colorRGB13), 0) 100%
        );
    }
}
