.n-guide {
  max-width: 540rem!important;
  min-width: 540rem!important;
  background-color: rgba(var(--colorRGB6), 1)!important;
  border: 6rem solid var(--colorHEX13)!important;
  border-radius: 4rem!important;
  color: #FFFFFF!important;
  box-shadow: 0rem 12rem 36rem rgba(0, 0, 0, 0.35)!important;
  padding: 40rem!important;
  &__body {
    &__title {
      margin-bottom: 18rem;
      font-size: 28rem;
      line-height: 35rem;
      color: #FFFFFF;
    }
    &__text {
      font-size: 18rem;
      line-height: 27rem;
      display: flex;
      align-items: center;
      color: #FFFFFF;
      margin: 18rem 0rem;
    }
    &__line {
      height: 1rem;
      width: 100%;
      background: var(--colorHEX13);
      -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
  }
  &__nav {
    &__nex-prev-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12rem 24rem;
      
      position: static;
      width: 80rem;
      height: 50rem;
      left: 380rem;
      top: 24rem;
      
      background: #FFFFFF;
      border: 1rem solid var(--colorHEX13);
      box-sizing: border-box;
      border-radius: 4rem;
      &__disabled {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12rem 24rem;
        
        position: static;
        width: 80rem;
        height: 50rem;
        left: 380rem;
        top: 24rem;
        
        background: rgba(var(--colorRGB6), 1)!important;
        border: 1rem solid var(--colorHEX8);
        box-sizing: border-box;
        border-radius: 4rem;
        svg {
          path {
            stroke: var(--colorHEX8)!important;
          }
        }
      }
      svg {
        path {
          stroke: var(--colorHEX13)!important;
        }
      }
    }
  }
}

.bgjRaZ {
  position: absolute;
  font-family: monospace;
  background: var(--colorHEX13)!important;
  height: 72rem!important;
  line-height: 71rem!important;
  padding-left: 0.8125em;
  padding-right: 32.5rem!important;
  font-size: 28rem!important;
  border-radius: 50%!important;
  color: white;
  text-align: center;
  // box-shadow: 0 0.25em 0.5em rgb(0 0 0 / 30%);
  top: -25rem!important;
  left: -35rem!important;
}

.sc-AxgMl {
  position: absolute;
  font-family: monospace;
  background: var(--colorHEX13)!important;
  height: 72rem!important;
  line-height: 71rem!important;
  padding-left: 0.8125em;
  padding-right: 32.5rem!important;
  font-size: 28rem!important;
  border-radius: 50%!important;
  color: white;
  text-align: center;
  // box-shadow: 0 0.25em 0.5em rgb(0 0 0 / 30%);
  top: -25rem!important;
  left: -35rem!important;
}

.gtkSUb {
  position: absolute;
  top: 22rem;
  right: 22rem;
  width: 30rem!important;
  height: 30rem!important;
  color: var(--colorHEX13)!important;
  svg {
    width: 15rem!important;
    height: 15rem!important;
  }
  &:hover {
    width: 30rem!important;
    height: 30rem!important;
    border-radius: 32rem;
    background: rgba(255, 255, 255, 0.6)!important;
    color: var(--colorHEX7)!important;
    svg {
      width: 15rem!important;
      height: 15rem!important;
    }
  }
}

.reactour__close {
  position: absolute;
  top: 22rem;
  right: 22rem;
  width: 30rem!important;
  height: 30rem!important;
  color: var(--colorHEX13)!important;
  svg {
    width: 15rem!important;
    height: 15rem!important;
  }
  &:hover {
    width: 30rem!important;
    height: 30rem!important;
    border-radius: 32rem;
    background: rgba(255, 255, 255, 0.6)!important;
    color: var(--colorHEX7)!important;
    svg {
      width: 15rem!important;
      height: 15rem!important;
    }
  }
}

.hqBKFk {
  counter-increment: dot;
  width: 8rem;
  height: 8rem;
  border: 0;
  border-radius: 100%;
  padding: 0;
  display: block;
  margin: 8rem!important;
  -webkit-transition: opacity 0.3s,-webkit-transform 0.3s;
  -webkit-transition: opacity 0.3s,transform 0.3s;
  transition: opacity 0.3s,transform 0.3s;
  cursor: not-allowed;
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
  color: #fff!important;
  background: var(--colorHEX13)!important;
  width: 40rem!important;
  height: 40rem!important;
  border: 1rem solid var(--colorHEX13)!important;
  border-radius: 4rem!important;
  font-size: 20rem;
  line-height: 23rem;
}

.hqBKFk:before {
  content: counter(dot);
  position: absolute;
  bottom: 0!important;
  left: 14rem!important;
  opacity: 1!important;
  -webkit-transform: none!important;
  -ms-transform: none!important;
  transform: none!important;
  -webkit-transition: none!important;
  transition: 0!important;
  display: block;
  top: 8rem!important;
}

.kWBMYV {
  counter-increment: dot;
  width: 8rem;
  height: 8rem;
  border: 0!important;
  border-radius: 100%;
  padding: 0!important;
  display: block;
  margin: 4rem!important;
  -webkit-transition: opacity 0.3s,-webkit-transform 0.3s;
  -webkit-transition: opacity 0.3s,transform 0.3s;
  transition: opacity 0.3s,transform 0.3s;
  cursor: pointer;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background: none!important;
  color: none!important;
  background: none!important;
  color: #fff!important;
  width: 50rem!important;
  height: 50rem!important;
  border: 1rem solid var(--colorHEX13)!important;
  border-radius: 4rem!important;
  font-size: 20rem;
  line-height: 23rem;
}

.kWBMYV:before {
  content: counter(dot);
  position: absolute;
  bottom: 0!important;
  left: 18rem!important;
  opacity: 1!important;
  -webkit-transform: none!important;
  -ms-transform: none!important;
  transform: none!important;
  -webkit-transition: none!important;
  transition: 0!important;
  display: block;
  top: 13rem!important;
}

.bsdNNq  {
  counter-increment: dot;
  width: 8rem;
  height: 8rem;
  border: 0!important;
  border-radius: 100%;
  padding: 0!important;
  display: block;
  margin: 4rem!important;
  -webkit-transition: opacity 0.3s,-webkit-transform 0.3s;
  -webkit-transition: opacity 0.3s,transform 0.3s;
  transition: opacity 0.3s,transform 0.3s;
  cursor: pointer;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  background: none!important;
  color: none!important;
  background: none!important;
  color: #fff!important;
  width: 50rem!important;
  height: 50rem!important;
  border: 1rem solid var(--colorHEX13)!important;
  border-radius: 4rem!important;
  font-size: 20rem;
  line-height: 23rem;
}

.bsdNNq:before {
  content: counter(dot);
  position: absolute;
  bottom: 0!important;
  left: 10rem!important;
  right: 10rem!important;
  opacity: 1!important;
  -webkit-transform: none!important;
  -ms-transform: none!important;
  transform: none!important;
  -webkit-transition: none!important;
  transition: 0!important;
  display: block;
  top: 13rem!important;
}

.ldTCRx {
  display: none!important;
}

.sc-Axmtr {
  display: none!important;
}

.osncq {
  display:flex!important;
  justify-content: space-between!important;
}