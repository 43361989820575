.custom-column-filter-items-wrapper {
  & > .dropdown button {
    width: 100%;
    border-color: #1E70CD;
    color: #4e5155;
    background-color: #fff;
    &:after {
      border-width: 2px;
      transition: transform 0.2s;
    }
  }
  & > .show.dropdown button {
    border-color: #1E70CD;
    color: #4e5155;
    background-color: rgba(24, 28, 33, 0.03);
    &::after {
      transform: rotate(224deg);
    }
  }

  .custom-column-filter-input-wrapper {
    padding: 0 8px;
  }

  .custom-column-filter-items-wrapper {
    max-height: 300px;
    overflow: overlay;
  }
  .custom-column-filter-item.selected {
    background-color: #1E70CD;
    color: #fff;
  }
}