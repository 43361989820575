.n-packages-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  left: 0;
  background: var(--colorHEX6);
  transition: 0.5s;
  height: calc(100vh - 120rem);
  top: 60rem;
  &__header {
    padding: 40rem 44rem 14rem 44rem;
    &__text {
      font-weight: 500;
      font-size: 36rem;
      line-height: 44rem;
      text-align: right;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      cursor: default;
    }
  }
  &__filter {
    padding: 14rem 44rem 18rem 44rem;
    color: #dce8fa;
    &__search {
      input[type=text] {
        width: 100%;
        background: var(--colorHEX5);
        border: 1rem solid var(--colorHEX13);
        box-sizing: border-box;
        border-radius: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14.5rem 40rem 14.5rem 18rem;
        font-size: 16rem;
        line-height: 19rem;
        color: #FFFFFF;
        &::placeholder {
          font-size: 16rem;
          line-height: 19rem;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0.5) 66%, rgba(255, 255, 255, 0.5));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: var(--colorHEX7);
        }
        &:hover {
          background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
          color: #FFFFFF;
          &::placeholder {
            color: #FFFFFF;
          }
        }
        &:focus {
          background: var(--colorHEX4);
          color: #FFFFFF;
        }
      }
      svg {
        position: absolute;
        right: 60rem;
        top: 128rem;
        pointer-events: none;
        stroke: var(--colorHEX7)
      }
    }
  }
  &__nav {
    display: flex;
    padding-top: 32rem;
    &__sort-btn {
      width: 30rem;
      height: 30rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;
      padding: 3rem;
      cursor: pointer;
      svg {
        fill: var(--colorHEX13);
      }
      &:hover {
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
      }
    }
    &__sort-dropdown {
      position: relative;
      width: 165rem;
      margin: 0 10rem;
      padding: 4rem 12rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;

      color: rgba(255, 255, 255, 0.5);
      font-weight: 550;
      font-size: 14rem;
      line-height: 20rem;

      display: flex;
      align-items: center;
      text-align: center;
      cursor: default;
      svg {
        position: absolute;
        right: 12rem;
        margin-left: 4rem;
        fill: rgba(255, 255, 255, 0.3);
      }
      &:hover {
        color: rgba(255, 255, 255, 0.5);
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
        svg {
          fill: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
  &__hr-line {
    height: 1rem;
    background: var(--colorHEX13);
  }
  &__card-list {
    height: calc(100vh - 363rem);
    overflow: auto;
  }
  &__card {
    &__wrapper {
      padding: 20rem 36rem;
      height: auto;
      &:hover {
        background: rgba(var(--colorRGB13), 0.2);
        .n-packages-menu__card__nav {
          display: flex;
        }
      }
    }
    &__image {
      img {
        border: 1rem solid var(--colorHEX13);
        border-radius: 8rem;
      }
    }
    &__content {
      width: auto;
      padding: 0 0 0 24rem;
      align-items: flex-start;
      &__title {
        font-weight: 700;
        font-size: 20rem;
        line-height: 27rem;
        color: #FEFEFE;
        cursor: pointer;
      }
      &__descriptions {
        display: flex;
        flex-direction: column;
        height: 53rem;
        margin: 12rem 0;
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
        overflow: auto;
      }
    }
    &__package {
      display: flex;
      flex-direction: column;
    }
    &__package-block {
      margin-bottom: 12rem;
      &__title {
        font-weight: 500;
        font-size: 14rem;
        line-height: 21rem;
        color: #FFFFFF;
      }
      &__list {
        margin: 5rem 0;
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
        ul {
          margin-block-start: 1rem;
          margin-block-end: 1rem;
          padding-inline-start: 22rem;
        }
      }
    }
    &__package-price {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 15rem;
      line-height: 20rem;
    }
    &__nav {
      width: 270rem;
      display: none;
      flex-direction: column;
      justify-content: flex-end;
      &.active {
        display: flex!important;
      }
    }
    &__accordion-list {
      margin-top: 12rem;
      overflow: auto;
    }
    &__accordion {
      &__header {
        display: flex;
        align-items: center;
        padding: 8rem 36rem;
        cursor: pointer;
        &:hover {
          background: rgba(var(--colorRGB13), 0.2);
        }
        svg {
          fill: var(--colorHEX13)
  
        }
        &.active {
          background: rgba(var(--colorRGB13), 0.1);
          &:hover {
            background: rgba(var(--colorRGB13), 0.2);
          }
          svg {
            fill: var(--colorHEX13)
          }
        }
        &__title {
          margin: 0rem 12rem;
          font-weight: 700;
          font-size: 15rem;
          line-height: 20rem;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
          linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
  
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
          &.active {
            background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
            linear-gradient(0deg, var(--colorHEX14), var(--colorHEX14));
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
          }
        }
        &__icon-second {
          transition: all 0.4s ease-in-out;
          transform: rotate(0deg);
          margin-left: auto;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      &__body {
        background: rgba(var(--colorRGB13), 0.1);
      }
    }
    &__border {
      margin: 0 36rem 0 36rem;
      border-bottom: 1rem solid var(--colorHEX13);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      opacity: 0.2;
    }
  }
  &__card-widget-block {
    &__wrapper {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 12rem 36rem;
    }
    &__title {
      width: auto;
      padding-left: 16rem;
      font-weight: 700;
      font-size: 15rem;
      line-height: 20rem;
      color: #FEFEFE;        
    }
    &__status {
      margin-left: auto;
      padding-left: 20rem;
      font-weight: 700;
      font-size: 11rem;
      line-height: 12rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
      svg {
        margin: 4rem;
      }
    }
    &__border {
      margin: 0 36rem 0 36rem;
      border-bottom: 1rem solid var(--colorHEX13);
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      opacity: 0.2;      
    }
  }
  &__feature-plan {
    &__card {
      flex: 0 0 auto;
      padding: 20rem;
      &__wrapper {
        display: flex;
        flex-direction: column;
        margin: 20rem 20rem 11rem 20rem;
        border-bottom: 1rem solid rgba(var(--colorRGB13), 0.3);
      }
      &__title {
        position: relative;
        margin: 0 20rem 30rem 0;
        font-weight: 600;
        font-size: 24rem;
        line-height: 28rem;
        display: flex;
        align-items: center;
        color: #FFFFFF;
        &::after {
          content: "";
          width: 36rem;
          height: 2rem;
          background: var(--colorHEX13);
          position: absolute;
          bottom: -12rem;
          left: 0;
          z-index: -1;
          transition: all 0.3s ease 0s;
        }
      }
      &__info {
        border-bottom: 1rem solid rgba(var(--colorRGB13), 0.3);
        padding-bottom: 15rem;
        &__text {
          height: 80rem;
          max-height: 80rem;
          overflow-y: auto;
  
          font-weight: 400;
          font-size: 14rem;
          line-height: 20rem;
          color: #BEBEBE;
        }
        &__price {
          font-size: 20rem;
          line-height: 27rem;
          color: #FFFFFF;
          margin: 12rem 0rem;
        }
        &__btn {
          display: inline-block;
          margin-bottom: 26rem;
        }
      }
      &__permission {
        padding: 8rem 0;
        &__wrapper {
          height: 230rem;
          overflow-y: auto;
        }
        &__value {
          font-weight: 700;
          font-size: 14rem;
          line-height: 17rem;
          color: #FFFFFF;
        }
        ul {
          color: #BEBEBE;
          display: block;
          list-style-type: disc;
          margin-block-start: 0rem;
          margin-block-end: 0rem;
          margin-inline-start: 0rem;
          margin-inline-end: 0rem;
          padding-inline-start: 20rem;
          li {
            margin-bottom: 8rem;
          }
        }
      }
    }  
  }


  &__card-list-2 {
    position: relative;overflow: scroll; height: 600rem
  }

  &__btn-white {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 7.5rem 12rem;
    background: rgba(255, 255, 255, 0.8);
    border: 1rem solid #FFFFFF;
    border-radius: 4rem;
  
    color: var(--colorHEX2);
    
    font-size: 14rem;
    line-height: 21rem;
    transition: .25s background-color ease;
    cursor: pointer;
    svg {
      margin-right: 4rem;
      fill: var(--colorHEX2);
    }
    &:hover {
      background: #FFFFFF;
    }
    &:active {
      background: #FFFFFF;
    }
    &:disabled {
      opacity: 0.3;
      svg {
        opacity: 0.3;
      }
    }
  }
  &__btn-outline-white {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 7.5rem 12rem;
    background: none;
    border: 1rem solid #FFFFFF;
    border-radius: 4rem;
  
    color: #FFFFFF;
    
    font-size: 14rem;
    line-height: 21rem;
    transition: .25s background-color ease;
    cursor: pointer;
    &.active {
      background: rgba(15, 27, 45, 0.3);
      border: 1rem solid #0F1B2D;
    }
    svg {
      margin-right: 4rem;
      fill: var(--colorHEX2);
    }
    &:hover {
      background: rgba(255, 255, 255, 0.35);
    }
    &:active {
      background: rgba(15, 27, 45, 0.3);
      border: 1rem solid #0F1B2D;
    }
    &:disabled {
      opacity: 0.3;
      svg {
        opacity: 0.3;
      }
    }
  }
}
