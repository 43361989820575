// btn-lg
// btn
// btn-sm
// btn-xs

// @include button('n-button-header', 8rem 20rem, 4rem, #d33, #fff);
///////
.n-button-header {
  padding: 8rem 20rem;
  background: rgba(255, 255, 255, 0.2);
  outline: 1rem solid rgba(0, 0, 0, 0.1);
  border-radius: 4rem;
  -webkit-border-radius: 4rem;
  -webkit-outline-radius: 4rem;
  -moz-outline-radius: 4rem;
  overflow: hidden;
  font-size: 18rem;
  line-height: 29rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  transition: .25s background-color ease;
  cursor: pointer;

  &--people {
    margin-right: 24rem;
  }

  svg {
    margin-right: 8rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.35);
    outline: 1rem solid rgba(0, 0, 0, 0.1);
  }
}

.button-active-header {
  background: var(--colorHEX6);
  outline: 1rem solid rgba(0, 0, 0, 0.3);
}

///////

.n-button-workspace {
  padding: 8rem 20rem;
  background: rgba(255, 255, 255, 0.2);
  outline: 1rem solid rgba(var(--colorRGB2), 0.3);
  border-radius: 4rem;

  font-weight: 500;
  font-size: 15rem;
  line-height: 22rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    // margin-right: 8rem;
    fill: #FFFFFF;
  }

  &__title {
    display: flex;
    justify-content: space-around;

    &__text {
      margin: 0 20rem;
    }

    &__icon-close {
      display: none;
    }

    &__icon-edit {
      display: none;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.35);
    outline: 1rem solid rgba(var(--colorRGB2), 0.3);
  }
}

.button-active-workspace {
  background: rgba(var(--colorRGB2), 0.3);
  outline: 1rem solid rgba(var(--colorRGB2), 1);

  &__title {
    &__icon-close {
      align-items: center;
      // padding-right: 10rem;
      &:hover {
        svg {
          fill: #e30c0c !important;
        }
      }
    }
  }
}

.button-xs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 12rem;
  background: var(--colorHEX12);
  border-radius: 4rem;

  color: #FFFFFF;
  font-size: 14rem;
  line-height: 21rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--colorHEX14);
  }

  &:active {
    background: var(--colorHEX8);
  }
}

.button-m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 12rem;
  background: var(--colorHEX14);
  border-radius: 4rem;

  color: #FFFFFF;
  font-size: 14rem;
  line-height: 21rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--colorHEX14);
  }

  &:active {
    background: var(--colorHEX8);
  }
}

.button-lg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 11rem 24rem;
  background: var(--colorHEX11);
  border: 1rem solid var(--colorHEX8);
  border-radius: 4rem;

  color: #FFFFFF;
  font-size: 20rem;
  line-height: 23rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--colorHEX11);
  }

  &:active {
    background: var(--colorHEX8);
  }
}

.button-lg-outline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 11rem 24rem;
  border: 1rem solid var(--colorHEX8);
  border-radius: 4rem;

  color: var(--colorHEX8);
  font-size: 20rem;
  line-height: 23rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: var(--colorHEX8);
  }

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), var(--colorHEX11);
    color: #FFFFFF;
  }

  &:active {
    background: var(--colorHEX8);
  }

  &:disabled {
    border: 1rem solid #a9a9a9;
    color: #a9a9a9;
  }
}

// outline

.button-outlone-xm {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 12rem;
  background: rgba(255, 255, 255, 0.8);
  outline: 1rem solid rgba(var(--colorRGB2), 0.3);
  border-radius: 4rem;

  color: var(--colorHEX2);
  font-size: 14rem;
  line-height: 21rem;
  transition: .25s background-color ease;
  opacity: 0.8;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: var(--colorHEX8);
  }

  &:hover {
    opacity: 1;
  }

  &:active {
    outline: 1rem solid var(--colorHEX2);
    opacity: 1;
  }
}

.button-outlone-l {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12rem 20rem;
  // background: rgba(255, 255, 255, 0.8);
  outline: 1rem solid rgba(0, 0, 0, 0.3);
  border-radius: 4rem;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 18rem;
  line-height: 21rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    margin: 0 0 0 8rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.35);
  }

  &:active {
    background: rgba(255, 255, 255, 0.35);
  }
}

.button-outlone-white-l {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10rem 18rem;
  background: none;
  outline: 1rem solid #FFFFFF;
  border: none;
  border-radius: 4rem;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 15rem;
  line-height: 18rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    margin: 0 0 0 8rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.35);
  }

  &:active {
    background: rgba(255, 255, 255, 0.35);
  }
}

// white

.button-white-m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 12rem;
  background: rgba(255, 255, 255, 0.8);
  border: 1rem solid #FFFFFF;
  border-radius: 4rem;

  color: var(--colorHEX2);

  font-size: 14rem;
  line-height: 21rem;
  transition: .25s background-color ease;
  cursor: pointer;

  &__activated {
    padding: 4rem 12rem;
    border: 1rem solid var(--colorHEX13);
    border-radius: 4rem;
    color: #FFFFFF
  }

  svg {
    margin-right: 4rem;
    fill: var(--colorHEX2);
  }

  &:hover {
    background: #FFFFFF;
  }

  &:active {
    background: #FFFFFF;
  }

  &:disabled {
    opacity: 0.3;

    svg {
      opacity: 0.3;
    }
  }
}

.button-white-xs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0rem 12rem;
  background: rgba(255, 255, 255, 0.8);
  border: 1rem solid #FFFFFF;
  border-radius: 4rem;

  color: var(--colorHEX2);

  font-size: 14rem;
  // line-height: 21rem;
  transition: .25s background-color ease;
  height: 23rem;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: var(--colorHEX2);
  }

  &:hover {
    background: #FFFFFF;
  }

  &:active {
    background: #FFFFFF;
  }

  &:disabled {
    opacity: 0.3;

    svg {
      opacity: 0.3;
    }
  }
}


// borderless

.button-borderless-xl {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8rem 20rem;
  border-radius: 4rem;
  transition: .25s background-color ease;
  cursor: pointer;

  svg {
    // margin-right: 4rem;
    fill: #FFFFFF;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.35);
    outline: 1rem solid rgba(0, 0, 0, 0.1);
  }

  &:active {
    background: var(--colorHEX6);
    outline: 1rem solid rgba(0, 0, 0, 0.3);
  }

  &.active {
    background: var(--colorHEX6);
    outline: 1rem solid rgba(0, 0, 0, 0.3);

    &:hover {
      background: rgba(255, 255, 255, 0.35);
      outline: 1rem solid rgba(0, 0, 0, 0.1);
    }
  }
}


.n-button-white-s {
  display: inline-block;
  // display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 12rem;
  background: rgba(255, 255, 255, 0.8);
  border: 1rem solid #FFFFFF;
  border-radius: 4rem;

  color: var(--colorHEX2);

  font-size: 13rem;
  line-height: 21rem;
  transition: .25s background-color ease;
  // height: 23rem;
  cursor: pointer;

  svg {
    margin-right: 4rem;
    fill: var(--colorHEX2);
  }

  &:hover {
    background: #FFFFFF;
  }

  &:active {
    background: #FFFFFF;
  }

  &:disabled {
    opacity: 0.3;

    svg {
      opacity: 0.3;
    }
  }
}