.el-text {
  font-size: var(--el-text-font-size);
  color: var(--el-text-font-color, inherit);

  &.ellipsed {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    line-height: inherit;
  }

  &.underline {
    text-decoration: underline;
  }

  &.w-300 {
    font-weight: 300;
  }

  &.w-400 {
    font-weight: 400;
  }

  &.w-500 {
    font-weight: 500;
  }

  &.w-600 {
    font-weight: 600;
  }
  &.w-700 {
    font-weight: 700;
  }

  &.block {
    display: block;
  }

  &.align-center {
    text-align: center;
  }

  &.clamped {
    display: -webkit-inline-box;
    -webkit-line-clamp: var(--el-text-clamp-count, 1);
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &.error {
    --el-text-font-color: var(--red-color-muffled);
  }

  &.success {
    --el-text-font-color: var(--green-color);
  }

  &.primary {
    --el-text-font-color: var(--text-color);
  }

  &.secondary {
    color: #bebebe;
    --el-text-font-color: var(--secondary-text-color);
  }

  &.nonSelect {
    user-select: none;
  }

  &.clickable {
    cursor: pointer;
  }

  &.asButton {
    transition: color var(--transition-default);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: var(--text-color);
    }
  }
  &.lineHeight {
    line-height: var(--el-text-line-height, 24px);
  }
}
