.el-dropdown {
  position: relative;
  margin: 0;
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  & > * {
    user-select: none;
  }
  &.open > .el-dropdown-menu {
    display: block;
    border-top-width: 0;
  }
  &.open > .el-dropdown-toggle {
    border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
    color: var(--text-color);
    background: var(--colorHEX3);
    @include hover-active;
    .caret {
      svg {
        transform: rotate(180deg);
        use {
          --fill: var(--text-color);
        }
      }
    }
  }
  .el-dropdown-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    padding-right: 24px;
    position: relative;
    height: var(--el-dropdown-menu-height, 45px);
    color: var(--text-color-80);
    font-size: 14px;
    line-height: 20px;
    box-shadow: var(--input-box-shadow-default);
    border-radius: var(--border-radius-default);
    cursor: pointer;
    transition: var(--transition-default) background-color;
    @include hover-active;
    .caret {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 12px;
      height: var(--el-dropdowm-menu-height, 45px);
      svg {
        transform-origin: center;
        transition: all var(--transition-default) ease-in-out;
        transform: rotate(0deg);
        use {
          --fill: var(--text-color-50);
        }
      }
    }
  }
  .el-dropdown-menu {
    position: absolute;
    top: calc(var(--el-dropdown-menu-height, 45px) - 2px);
    z-index: 1000;
    display: none;
    float: left;
    min-width: 165px;
    padding: 5px 0;
    text-align: left;
    list-style: none;
    background: var(--colorHEX3);
    border-radius: 0 0 var(--border-radius-default) var(--border-radius-default);
    box-shadow: var(--input-box-shadow-default);
    li {
      height: var(--el-dropdown-menu-height, 45px);
      display: flex;
      align-items: center;
      cursor: pointer;
      @include hover-active;
      &.active > a {
        font-weight: 500;
      }
      & > a {
        display: block;
        padding: 4px 12px;
        white-space: nowrap;
        text-decoration: none;
        color: var(--text-color);
        font-size: 14px;
      }
    }
  }
}

.el-dropdown .el-dropdown-menu .active {
  background-color: rgba(var(--colorRGB13), 0.3);
  &:hover,
  &:active {
    background-color: rgba(var(--colorRGB13), 0.3);
  }
  & > a {
    color: var(--text-color-50);
  }
}
