body .public-DraftStyleDefault-block {
  margin: 0;
}

body .DraftEditor-editorContainer {
  background-color: #fff;
  border-left: 0.1rem solid transparent;
  position: relative;
  padding: 0.438rem 0.875rem;
  z-index: 1;
  font-size: 0.894rem;
  font-weight: 400;
  line-height: 1.54;
  color: #4e5155;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content {
  min-height: 90rem;
}