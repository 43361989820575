.n-textarea {
  textarea {
    outline: none !important;
    padding: 11rem 15rem;
    background: #ffffff;
    border: 1rem solid var(--colorHEX14);
    border-radius: 24rem;
    width: 100%;
    resize: none;
    min-height: 46rem!important;
    font-size: 15rem;
    line-height: 1.6;
    outline: none;
    color: var(--colorHEX5);
    &::placeholder {
      font-size: 15rem;
      line-height: 1.6;
      display: flex;
      align-items: center;
      color: var(--colorHEX5);
      opacity: 0.6;
    }
    &:hover, &:focus {
      border: 1px solid var(--colorHEX14);
      -webkit-box-shadow: 0 0 0 1px var(--colorHEX14);
      -moz-box-shadow: 0 0 0 1px var(--colorHEX14);
      box-shadow: 0 0 0 1px var(--colorHEX14);
    }
    // &::-webkit-scrollbar {
    //   border-radius: 6rem;
    //   width: 8rem;
    //   background: var(--colorHEX10);
    //   opacity: 0.5;
    // }
    // &::-webkit-scrollbar-track:hover {
    //   background: var(--colorHEX10);
    //   opacity: 0.7;
    //   cursor: pointer;
    // }
    // ::-webkit-scrollbar-track:active {
    //   background: var(--colorHEX10);
    //   cursor: pointer;
    // }
  }
}