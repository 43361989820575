.n-feature-plan {
  overflow-y: auto;
  height: calc(100vh - 400rem);

  &__scroll {
    padding: 22rem 36rem;

    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__prev {
      margin-right: 11rem;
      cursor: pointer;

      svg {
        fill: var(--colorHEX13)
      }
    }

    &__scroll {
      width: 670rem;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 20rem;
    }

    &__next {
      margin-left: 11rem;
      cursor: pointer;

      svg {
        fill: var(--colorHEX13)
      }
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 24rem 0 36rem;
    padding: 15rem 0;
  }

  &__card {
    flex: 0 0 auto;
    width: 280rem;
    height: 532rem;
    background: rgba(255, 255, 255, 0.04);
    border: 1rem solid rgba(var(--colorRGB2), 0.5);
    border-radius: 4rem;
    margin-right: 12rem;

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin: 20rem 20rem 11rem 20rem;
      border-bottom: 1rem solid rgba(var(--colorRGB13), 0.3);
    }

    &__title {
      position: relative;
      margin: 0 20rem 30rem 0;
      font-weight: 600;
      font-size: 24rem;
      line-height: 28rem;
      display: flex;
      align-items: center;
      color: #FFFFFF;

      &::after {
        content: "";
        width: 36rem;
        height: 2rem;
        background: var(--colorHEX13);
        position: absolute;
        bottom: -12rem;
        left: 0;
        z-index: -1;
        transition: all 0.3s ease 0s;
      }
    }

    &__info {
      border-bottom: 1rem solid rgba(var(--colorRGB13), 0.3);

      &__text {
        height: 80rem;
        max-height: 80rem;
        overflow-y: auto;

        font-weight: 400;
        font-size: 14rem;
        line-height: 20rem;
        color: #BEBEBE;
      }

      &__price {
        font-size: 20rem;
        line-height: 27rem;
        color: #FFFFFF;
        margin: 12rem 0rem;
      }

      &__btn {
        display: inline-block;
        margin-bottom: 26rem;
      }
    }

    &__permission {
      padding: 8rem 0;

      &__wrapper {
        height: 230rem;
        overflow-y: auto;
      }

      &__value {
        font-weight: 700;
        font-size: 14rem;
        line-height: 17rem;
        color: #FFFFFF;
      }

      ul {
        color: #BEBEBE;
        display: block;
        list-style-type: disc;
        margin-block-start: 0rem;
        margin-block-end: 0rem;
        margin-inline-start: 0rem;
        margin-inline-end: 0rem;
        padding-inline-start: 20rem;

        li {
          margin-bottom: 8rem;
          font-size: 16rem;
        }
      }
    }
  }
}

.n-feature-plan__scroll__scroll, .n-feature-plan__list {
  overflow-x: scroll;
  overflow-y: hidden;
}
  