.privacy {
  padding: 50px 10px 50px 10px;
}

.privacy-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 900px;
}

@media (max-width: 767px) {
  .privacy-container {
    max-width: 767px;
  }
}

@media (max-width: 1024px) {
  .privacy-container {
    max-width: 1024px;
  }
}
