/* end forms */

html.scale {
  .modal-open .modal {
    overflow: hidden;
  }

  .form-control {
    height: calc(1.54em + 0.876rem * 16 + 2px);
    padding: 0.438rem * 16 0.875rem * 16;
    font-size: .894rem * 16;
    border: 1px solid rgba(24, 28, 33, .1);
    border-radius: 0.25rem * 16;
  }

  .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
    padding-right: 16 * 0.75rem;
    padding-left: 16 * 0.75rem;
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.54em + 16 * 0.876rem + 2px);
    padding: 16 * 0.438rem 16 * 2.75rem 16 * 0.438rem 16 * 0.875rem;
    font-size: 16 * .894rem;
    font-weight: 400;
    line-height: 1.54;
    color: #4e5155;
    vertical-align: middle;
    border: 1px solid rgba(24, 28, 33, .1);
    border-radius: 16 * 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .form-group-lg .switcher .switcher-indicator, .switcher-lg .switcher-indicator {
    width: 16 * 3.25rem;
    height: 16 * 1.75rem;
    font-size: 16 * .625rem;
    line-height: 16 * 1.75rem;
  }

  .switcher .switcher-indicator {
    width: 16 * 2.375rem;
    height: 16 * 1.375rem;
    font-size: 16 * .625rem;
    line-height: 16 * 1.375rem;
  }

  .switcher-indicator {
    border-radius: 16 * 60rem;
  }

  .form-group-lg .switcher .switcher-indicator:after, .switcher-lg .switcher-indicator:after {
    top: 0;
    margin: 16 * 0.25rem 0 0 16 * 0.25rem;
    width: 16 * 1.25rem;
    height: 16 * 1.25rem;
  }

  .switcher .switcher-indicator:after {
    top: 0;
    margin: 16 * 0.25rem 0 0 16 * 0.25rem;
    width: 16 * 0.875rem;
    height: 16 * 0.875rem;
  }

  .switcher-lg .switcher-no, .form-group-lg .switcher .switcher-no {
    padding-right: 16 * 0.25rem;
    padding-left: 16 * 1.5rem;
  }

  .switcher .switcher-no {
    padding-right: 16 * 0.25rem;
    padding-left: 16 * 1.125rem;
  }

  .form-group-lg .switcher, .switcher-lg {
    padding-left: 16 * 3.25rem;
    min-height: 16 * 1.75rem;
    font-size: 16 * 1rem;
  }

  .switcher {
    margin-right: 16 * 0.75rem;
    border-radius: 16 * 60rem;
    padding-left: 16 * 2.375rem;
    min-height: 16 * 1.375rem;
    font-size: 16 * .894rem;
  }

  @media (min-width: 576px) {
    .modal-dialog-centered, {
      &::before {
        height: calc(100% - 16 * 3.5rem);
        min-height: calc(100% - 16 * 3.5rem);
      }

      min-height: calc(100% - 16 * 3.5rem);
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 16 * 35rem;
      margin: 16 * 1.75rem auto;

      &:before {
        height: calc(100% - 16 * 3.5rem);
      }
    }
  }

  .custom-control {
    min-height: 1.31418rem * 16;
    padding-left: 1.563rem * 16;
  }

  .custom-control-input {
    width: 1.125rem * 16;
    height: 1.21959rem * 16;
  }

  .custom-control-label:after, .custom-control-label:before {
    top: .09459rem * 16;
    left: -1.563rem * 16;
    width: 1.125rem * 16;
    height: 1.125rem * 16;
  }

  .custom-checkbox .custom-control-label:before {
    border-radius: .25rem * 16;
  }

  .custom-control {
    min-height: 1.125rem * 16;
    margin-bottom: .5rem * 16;
  }

  .custom-control-label:before {
    top: .0625rem * 16;
  }

  /* end forms */


  /* datepicker */

  .react-datepicker__header {
    padding-top: .5rem * 16;
  }

  .react-datepicker__current-month {
    line-height: 2rem * 16;
  }

  .react-datepicker__current-month, .react-datepicker__day-names {
    padding: 0 .5rem * 16;
  }

  .react-datepicker__day-name {
    font-size: 16 * 0.75rem;
  }

  .react-datepicker__day-names {
    margin-top: .5rem * 16;
  }

  .react-datepicker__day-name {
    width: 2rem * 16;
    line-height: 2rem * 16;
  }

  .react-datepicker__navigation {
    line-height: 2rem * 16;
    top: 1.125rem * 16;
    height: .625rem * 16;
    width: .625rem * 16;
  }

  .react-datepicker__navigation--previous {
    left: 1.125rem * 16;
  }

  .react-datepicker__navigation--next {
    right: 1.125rem * 16;
  }

  .react-datepicker__month {
    padding: .5rem * 16;
  }

  .react-datepicker__day {
    width: 2rem * 16;
    line-height: 2rem * 16;
  }

  .react-datepicker__day-name {
    font-size: .75rem * 16;
  }

  .react-datepicker__day {
    border-radius: .25rem * 16;
  }

  .rrich-message__send-time__date__input .react-datepicker {
    width: 248rem;
  }

  /* end datepicker */

  /* buttons */


  .btn {
    padding: .438rem * 16 1.125rem * 16;
    font-size: .894rem * 16;
    border-radius: .25rem * 16;
  }

  .btn-xs {
    padding: 0 .5rem * 16;
    font-size: .75rem * 16;
    border-radius: .25rem * 16;
  }

  ////
  .custom-control {
    min-height: 16 * 1.31418rem;
    padding-left: 16 * 1.563rem;
  }

  .custom-control-inline {
    margin-right: 16 * 1rem;
  }

  .custom-control-input {
    width: 16 * 1.125rem;
    height: 16 * 1.21959rem;
  }

  .custom-control-label:after, .custom-control-label:before {
    top: 16 * .09459rem;
    left: 16 * -1.563rem;
    width: 16 * 1.125rem;
    height: 16 * 1.125rem;
  }


  .custom-control {
    min-height: 16 * 1.125rem;
    margin-bottom: 16 * .5rem;
  }

  .custom-control-label:before {
    top: 16 * .0625rem;
  }

  .custom-radio .custom-control-label:before {
    background-size: 16 * .5rem;
    left: 0;
  }


  /*! CSS Used from: https://alpha.d342efxjm7dd16.amplifyapp.com/static/css/main.e0ca02ad.chunk.css */

  .custom-control {
    min-height: 16 * 1.31418rem;
    padding-left: 16 * 1.563rem;
  }

  .custom-control-input {
    left: 0;
    width: 16 * 1.125rem;
    height: 16 * 1.21959rem;
  }

  .custom-control-label:after, .custom-control-label:before {
    top: 16 * .09459rem;
    left: 16 * -1.563rem;
    width: 16 * 1.125rem;
    height: 16 * 1.125rem;
  }

  .custom-checkbox .custom-control-label:before {
    border-radius: 16 * .25rem;
  }

  .custom-control {
    min-height: 16 * 1.125rem;
    margin-bottom: 16 * .5rem;
  }

  .custom-control-label:before {
    top: 16 * .0625rem;
    left: 0;
  }

  custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3E%3Cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3E%3C/svg%3E");
  }


  /*! CSS Used from: https://alpha.d342efxjm7dd16.amplifyapp.com/static/css/main.e0ca02ad.chunk.css */

  .form-control {
    height: calc(1.54em + 16 * .876rem + 2px);
    padding: 16 * .438rem 16 * .875rem;
    font-size: 16 * .894rem;
  }

  .form-control-sm {
    height: calc(1.5em + 16 * .376rem + 2px);
    padding: 16 * .188rem 16 * .625rem;
    font-size: 16 * .75rem;
  }

  .form-control-sm {
    border-radius: 16 * .25rem;
  }

  /*! CSS Used from: https://alpha.d342efxjm7dd16.amplifyapp.com/static/css/main.e0ca02ad.chunk.css */

  .react-select .react-select__value-container {
    line-height: 16 * 1.5rem;
  }

  .react-select__value-container {
    padding: 0 0 0 16 * .875rem;
  }

  /*! CSS Used from: Embedded */
  .css-1okebmr-indicatorSeparator {
    margin-bottom: 8rem;
    margin-top: 8rem;
  }

  /*! CSS Used from: Embedded */
  .css-tlfecz-indicatorContainer {
    padding: 8rem;
  }

  .react-select__indicator svg {
    height: 20rem !important;
    width: 20rem !important;
  }


  /*! CSS Used from: https://alpha.d342efxjm7dd16.amplifyapp.com/vendor/fonts/fontawesome.css */
  .sr-only {
    height: 1rem;
    margin: -1rem;
    width: 1rem;
  }

  .Loader_SpinnerEl__23xdC {
    border-width: 2rem !important;
    width: 16 * 3rem !important;
    height: 16 * 3rem !important;
  }


  .spinner-border {
    width: 16 * 2rem;
    height: 16 * 2rem;
    border: .25em solid;
    border-right: .25em solid transparent;
  }

  .sr-only {
    width: 1rem;
    height: 1rem;
  }

  /*! CSS Used from: Embedded */
  *, *::before, *::after {
    box-sizing: border-box;
  }


  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input, button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button, input {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button, [type="button"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner, [type="button"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  ///*! CSS Used from: Embedded */
  .emoji-mart {
    border-radius: 0 5px 0 0 !important;
    position: absolute;
    bottom: 59rem;
    width: 298rem !important;
    z-index: 9999;
  }

  .emoji-mart-anchor-bar {
    display: none;
  }

  .emoji-mart-emoji > span {
    width: 24rem !important;
    height: 24rem !important;
  }

  /*! CSS Used from: Embedded */
  .emoji-mart-preview {
    height: 70rem;
  }

  /*! CSS Used from: Embedded */

  .emoji-mart {
    font-size: 16rem;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }

  .emoji-mart .emoji-mart-emoji {
    padding: 6rem;
  }

  .emoji-mart-bar:first-child {
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .emoji-mart-bar:last-child {
    border-top-width: 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .emoji-mart-anchors {
    padding: 0 6rem;
  }

  .emoji-mart-anchor {
    padding: 12rem 4rem;
  }

  .emoji-mart-anchor-bar {
    bottom: -3rem;
    left: 0;
    width: 100%;
    height: 3rem;
  }

  .emoji-mart-anchors svg {
    height: 18rem;
    width: 18rem;
  }

  .emoji-mart-scroll {
    height: 270rem;
    padding: 0 6rem 6rem 6rem;
  }

  .emoji-mart-search {
    margin-top: 6rem;
    padding: 0 6rem;
  }

  .emoji-mart-search input {
    font-size: 16rem;
    padding: 5rem 25rem 6rem 10rem;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
  }

  .emoji-mart-search-icon {
    top: 7rem;
    right: 11rem;
    padding: 2rem 5rem 1rem;
  }

  .emoji-mart-category .emoji-mart-emoji:hover:before {
    top: 0;
    left: 0;
  }

  .emoji-mart-category-label span {
    padding: 5rem 6rem;
  }

  .emoji-mart-preview {
    height: 70rem;
  }

  .emoji-mart-preview-emoji {
    left: 12rem;
  }

  .emoji-mart-preview-data {
    left: 68rem;
    right: 12rem;
  }

  .emoji-mart-preview-skins {
    right: 30rem;
  }

  .emoji-mart-title-label {
    font-size: 26rem;
  }

  .emoji-mart-skin-swatches {
    padding: 2rem 0;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
  }

  //
  .emoji-mart-anchor {
    display: flex !important;
  }

  .emoji-mart-skin-swatch.selected {
    width: 16rem;
    padding: 0 2rem;
  }

  .emoji-mart-skin-swatch.selected:after {
    width: 4rem;
    height: 4rem;
    margin: -2rem 0 0 -2rem;
  }

  .emoji-mart-skin {
    max-width: 12rem;
  }

  /*! CSS Used from: Embedded */
  .rrich-message__user-template__select .react-select__control {
    border-radius: 0.25rem 0 0 0.25rem !important;
    border-top: 1rem solid rgba(24, 28, 33, 0.1) !important;
    border-right: 1rem solid rgba(24, 28, 33, 0.1) !important;
    border-bottom: 1rem solid rgba(24, 28, 33, 0.1) !important;
    border-left: 1rem solid rgba(24, 28, 33, 0.1) !important;

    min-height: calc(1.54em + 16 * 0.876rem + 2px);
  }

  /*! CSS Used from: Embedded */
  .react-select .react-select__control {
    box-shadow: none;
  }

  .react-select .react-select__placeholder {
    margin: 0;
  }

  .react-select .react-select__value-container {
    line-height: 1.5rem * 16;
  }

  .react-select .react-select__value-container > div:last-child {
    margin: 0;
    padding: 0;
  }

  .react-select .react-select__indicator > svg {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .react-select__control {
    border: 1px solid rgba(24, 28, 33, 0.1);
    border-radius: 0.25rem;
    background: #fff;
    min-height: calc(1.54em + 0.876rem + 2px);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .react-select__control:hover {
    border-color: rgba(24, 28, 33, 0.1);
  }

  .react-select__placeholder {
    color: #babbbc;
  }

  .react-select__single-value {
    overflow: unset;
  }

  .react-select__value-container {
    padding: 0 0 0 16 * 0.875rem;
  }

  .react-select__value-container > div:last-child {
    color: #4e5155;
  }

  .react-select__indicator, .react-select__indicator:hover {
    color: #babbbc;
    padding: 8rem;
  }

  .react-select__indicator-separator {
    background: rgba(24, 28, 33, 0.1);
  }

  ////
  .react-select__menu {
    margin: 0.125rem 0;
    border-radius: 0.25rem;
  }

  .react-select__menu-list {
    padding: 16 * 0.3125rem 0;
  }

  .react-select__option {
    font-size: 16 * 0.894rem;
    padding: 16 * 0.438rem 16 * 1.25rem;
  }

  /*! CSS Used from: Embedded */
  *, *::before, *::after {
    box-sizing: border-box;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  input {
    overflow: visible;
  }

  @media print {
    *, *::before, *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }
  /*! CSS Used from: Embedded */
  :focus {
    outline: none !important;
  }

  html.scale ::-webkit-scrollbar {
    width: 8rem;
  }

  html.scale ::-webkit-scrollbar-thumb {
    border-radius: 100rem;
  }

  html ::-webkit-scrollbar {
    width: 8px;
  }

  html ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  html ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: rgba(44, 79, 130, 0.3);
    background: rgba(var(--colorRGB7), 0.3);
  }

  html ::-webkit-scrollbar-thumb:hover {
    background: rgba(44, 79, 130, 0.35);
    background: rgba(var(--colorRGB7), 0.35);
  }

  /*! CSS Used from: Embedded */
  html.scale .react-select .react-select__value-container {
    line-height: 24rem;
  }

  html.scale .css-1okebmr-indicatorSeparator {
    margin-bottom: 8rem;
    margin-top: 8rem;
  }

  html.scale .css-tlfecz-indicatorContainer {
    padding: 8rem;
  }

  html.scale .react-select__indicator svg {
    height: 20rem !important;
    width: 20rem !important;
  }

  /*! CSS Used from: Embedded */
  .css-yk16xz-control {
    min-height: 38rem;
  }

  /*! CSS Used from: Embedded */
  .css-1wa3eu0-placeholder {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  /*! CSS Used from: Embedded */
  .css-b8ldur-Input {
    margin: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  /*! CSS Used from: Embedded */
  .css-1okebmr-indicatorSeparator {
    margin-bottom: 8rem;
    margin-top: 8rem;
  }

  /*! CSS Used from: Embedded */
  .css-tlfecz-indicatorContainer {
    padding: 8rem;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 10rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 10rem;
  }

  blockquote {
    margin: 0 0 10rem;
  }

  h3 {
    margin-bottom: 10rem;
    font-weight: 500;
    line-height: 1.1;
  }

  h3 {
    font-size: 15.63rem;
  }

  .blockquote {
    margin-bottom: 10rem;
    font-size: 11.175rem;
  }
  .modal-body {
    padding: 15.625rem;
  }
}

