.el-store-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  overflow: overlay;
}

.el-store-item {
  transition: filter var(--transition-default);
  background-color: var(--parent-background-color);
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  font-family: Noto Sans Display;
  font-weight: 400;
  transition: background-color var(--transition-default);
  border-radius: var(--border-radius-default);
  overflow: hidden;
  height: var(--store-item-height);
  cursor: pointer;

  &:hover {
    background-color: #4e8de81a;
  }
  &:active {
    background-color: #4e8ee849;
  }

  &:last-child {
    margin-bottom: 16px;
  }

  &.selected {
    background-color: #4e8de840;

    &:hover {
      background-color: #4e8de840;
    }
  }

  &.el-store-item-small-lr-padding {
    padding: 14px 12px;
  }
}

// .el-store-item + .el-store-item {
//   margin-top: 12px;
// }

.el-store-item-logo {
  width: var(--store-item-logo-width);
  height: var(--store-item-logo-height);
  min-width: var(--store-item-logo-width);
  overflow: hidden;
  border-radius: var(--border-radius-default);
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    border: 1px solid #4e8de880;
    position: absolute;
    border-radius: var(--border-radius-default);
    inset: 0;
  }
  &.default-size {
  }
  &.medium-size {
    --store-item-logo-width: 96px;
    --store-item-logo-height: 72px;
  }
}

.el-store-item-name {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--text-color);
  padding-left: 20px;
  flex: 1;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  min-width: 100px;
  .ellipsed {
    width: auto;
  }
  &.withSubscribers {
    flex-direction: column;
    justify-content: center;
    align-items: normal;
  }
}

.el-store-item-author {
  text-align: center;
  color: #ffffffcc;
  padding: 0 16px;
  width: 180px;
  min-width: 180px;
  display: inline-flex;

  &.centered {
    justify-content: center;
  }
}

.el-store-item-statuses {
  justify-content: center;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-width: 80px;
}

.el-store-item-status {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.el-store-item-author,
.el-store-item-statuses,
.el-store-details-description {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.el-store-item-statuses {
  line-height: 16px;
}

.el-store-details-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  -ms-flex: 1 1;
  flex: 1 1;
  overflow: hidden;
  position: relative;
  transition: opacity var(--transition-default);
  color: #ffffffcc;
}

.el-store-details-description {
  position: relative;
  font-family: Noto Sans Display;
  font-weight: 400;
  text-align: left;
  color: #ffffffcc;
  padding-top: 24px;
  width: calc(
    var(--store-details-width) - 2 * var(--el-content-container-padding, 32px) -
      var(--scroll-thumb-width, 8px)
  );
  overflow: hidden;

  .el-store-details-description-withicon {
    display: flex;
    gap: 4px;

    svg {
      min-width: 24px;
    }
  }

  &.disclaimerMode {
    font-size: 11px;
    color: #bebebe;
    line-height: 15.4px;
  }

  .inner-description {
    span,
    p {
      color: var(--text-color-80) !important;
    }
  }
}

.el-store-details-description-outer {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.el-store-details-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
}

.el-store-item-inner {
  border-radius: var(--border-radius-less);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.el-store-item-inner-left-bordered {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    background: #4e8de880;
  }
}

.el-store-item-chart {
  width: 120px;
  height: 60px;
}

.el-store-item-percent {
  font-family: Noto Sans Display;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: right;
  min-width: 100px;
  padding-left: 16px;

  &.growth {
    color: var(--growth-color);
  }

  &.decline {
    color: var(--decline-color);
  }
}

.el-store-details-program {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

.el-store-copytrading-container {
  max-height: 100vh;
}

.el-store-item-channel-status {
  margin-left: 6px;
  font-size: 75%;
  display: inline-block;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--store-item-channel-color);
  color: var(--store-item-channel-color);
  line-height: 18px;
  padding: 0 4px;
  &.submitted {
    --store-item-channel-color: var(--text-color-80);
  }
  &.canceled {
    --store-item-channel-color: var(--red-color);
  }
  &.withoutOffset {
    margin: 0;
  }
}

.el-store-subscribers-count {
  font-size: var(--el-text-font-size, 12px);
  color: var(--text-color-50);
  text-transform: none;
}
