.n-copytrading-menu {
  width: 0;
  position: fixed;
  z-index: 999;
  left: 0;
  background: rgba(var(--colorRGB5), 1);
  transition: 0.5s;
  height: calc(100vh - 120rem);
  top: 60rem;

  &__inputs-connect{
    display: flex;
    padding: 0 0 44rem 0;

    .sidebar-input-search-wrapper{
      width: 100%;
    }


    &>*{
      margin-left: 15rem;
      &:first-child{
        margin-left: 0;
      }
    }
  }

  &__wrapper {
    // background-color: var(--colorHEX7);
    background: linear-gradient(180deg, rgba(var(--colorRGB13), 0.2) 0%, rgba(var(--colorRGB13), 0) 100%);
    height: 100%;
    overflow: auto;
  }

  &__header {
    padding: 44rem;

    &__text {
      font-weight: 500;
      font-size: 36rem;
      line-height: 48rem;
      text-align: right;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      cursor: default;
    }
  }

  &__filter {
    padding: 44rem 44rem 18rem 44rem;
    color: #dce8fa;

    &__search {
      input[type=text] {
        width: 100%;
        background: var(--colorHEX5);
        border: 1rem solid var(--colorHEX13);
        box-sizing: border-box;
        border-radius: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14.5rem 40rem 14.5rem 18rem;
        font-size: 16rem;
        line-height: 19rem;
        color: #FFFFFF;

        &::placeholder {
          font-size: 16rem;
          line-height: 19rem;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0.5) 66%, rgba(255, 255, 255, 0.5));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: var(--colorHEX7);
        }

        &:hover {
          background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
          color: #FFFFFF;

          &::placeholder {
            color: #FFFFFF;
          }
        }

        &:focus {
          background: var(--colorHEX4);
          color: #FFFFFF;
        }
      }

      svg {
        position: absolute;
        right: 60rem;
        top: 128rem;
        pointer-events: none;
        stroke: var(--colorHEX7)
      }
    }
  }

  &__cards-container {
    height: calc(100vh - 464rem);
    overflow: auto;
  }

  &__nav {
    display: flex;
    padding-top: 32rem;

    &__sort-btn {
      width: 30rem;
      height: 30rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;
      padding: 3rem;
      cursor: pointer;

      svg {
        fill: var(--colorHEX13);
      }

      &:hover {
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
      }
    }

    &__sort-dropdown {
      position: relative;
      width: 165rem;
      margin: 0 10rem;
      padding: 4rem 12rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;

      color: rgba(255, 255, 255, 0.5);
      font-weight: 550;
      font-size: 14rem;
      line-height: 20rem;

      display: flex;
      align-items: center;
      text-align: center;
      cursor: default;

      svg {
        position: absolute;
        right: 12rem;
        margin-left: 4rem;
        fill: rgba(255, 255, 255, 0.3);
      }

      &:hover {
        color: rgba(255, 255, 255, 0.5);
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);

        svg {
          fill: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  &__hr-line {
    height: 1rem;
    background: var(--colorHEX13);
  }

  .programs-container {
    background: linear-gradient(180deg, rgba(var(--colorRGB13), 0.2) 0%, rgba(var(--colorRGB13), 0) 100%);
    // overflow: auto;
    // height: 70vh;
    height: calc(100vh - 300rem);
    padding: 36rem;
    padding-left: 42rem;    

    &__top{
      display: flex;
      align-items: center;
    }

    .n-copytrading-menu__hr-line{
      margin: 16rem 0;
    }

    &__cards{
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 370rem;
      grid-gap: 8rem;
      // overflow: auto;
      padding: 0 0 36rem 0;
    }
  }
}