.el-aside-hovered {
  &.hovered {
    .el-aside-item .el-button.inlineFlex .el-button-center {
      animation-name: fade-in;
      animation-fill-mode: forwards;
      animation-duration: var(--transition-default);
      animation-delay: var(--transition-default);
    }
  }
  .el-aside-item .el-button.inlineFlex .el-button-center {
    animation-name: fade-out;
    animation-fill-mode: forwards;
    animation-duration: var(--transition-default);
  }
}

.el-aside {
  font-size: 18px;
  height: 100vh;
  max-height: 100vh;
  background: linear-gradient(
      90deg,
      rgba(17, 17, 17, 0) 0.6%,
      rgba(17, 17, 17, 0.2) 100.6%
    ),
    linear-gradient(0deg, #3764a4, #3764a4);
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
  &.withPadding {
    padding: var(--aside-padding, 20px 20px 28px);
  }
}
.el-aside-bottom {
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
}

.el-aside-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  &.spaces {
    margin: 12px 0;
  }
  &.nested {
    margin-left: 1.25rem;
  }
  &.asDropdown {
    transition: max-height var(--transition-default);
    max-height: 0px;
    overflow: hidden;
    & > * {
      opacity: 0;
    }
  }
  &.asDropdown.asDropdownOpened {
    margin-top: 8px;
    max-height: calc(
      var(--max-aside-link-height, 72px) * var(--aside-dropdown-items)
    );
    & > * {
      animation-name: fade-in;
      animation-fill-mode: forwards;
      animation-duration: var(--transition-quick);
      animation-delay: var(--transition-quick);
    }
  }
}

.el-aside-section-logo {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;
  img {
    max-height: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 3px;
    overflow: hidden;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  99% {
    display: inherit;
    opacity: 0;
  }
  100% {
    // display: none;
  }
}

.el-aside-bottom {
}

.el-aside-logo {
  width: var(--aside-logo-width, 160px);
}

.el-aside-item {
  min-height: var(--el-aside-item-min-height);
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  position: relative;
  cursor: pointer;
  &.nested {
    margin-left: 20px;
  }
}

.el-aside-link {
  width: 100%;
  height: 100%;
  font-size: 14px;
  min-height: inherit;
  border-radius: var(--button-border-radius);
  &.groupLink {
    display: flex;
  }
}

.el-aside-link-active {
  & > button.aside {
    background-color: #15263e40;
  }
  & > button.aside:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
}

.el-aside-button-new-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}

.el-aside-divider {
  width: 100%;
  height: 1px;
  background-color: #ffffff1a;
  margin: 4px 0;
}
