.n-links-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  left: 0;
  background: var(--colorHEX6);
  transition: 0.5s;
  height: calc(100vh - 120rem);
  top: 60rem;

  &__header {
    padding: 44rem 44rem 14rem 44rem;

    &__text {
      font-weight: 500;
      font-size: 36rem;
      line-height: 40rem;
      text-align: right;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      cursor: default;
    }
  }

  &__filter {
    padding: 14rem 44rem 18rem 44rem;
    color: #dce8fa;

    &__search {
      input[type=text] {
        width: 100%;
        background: var(--colorHEX5);
        border: 1rem solid var(--colorHEX13);
        box-sizing: border-box;
        border-radius: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 14.5rem 40rem 14.5rem 18rem;
        font-size: 16rem;
        line-height: 19rem;
        color: #FFFFFF;

        &::placeholder {
          font-size: 16rem;
          line-height: 19rem;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0.5) 66%, rgba(255, 255, 255, 0.5));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: var(--colorHEX7);
        }

        &:hover {
          background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
          color: #FFFFFF;

          &::placeholder {
            color: #FFFFFF;
          }
        }

        &:focus {
          background: var(--colorHEX4);
          color: #FFFFFF;
        }
      }

      svg {
        position: absolute;
        right: 60rem;
        top: 128rem;
        pointer-events: none;
        stroke: var(--colorHEX7)
      }
    }
  }

  &__nav {
    display: flex;
    padding-top: 32rem;

    &__sort-btn {
      width: 30rem;
      height: 30rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;
      padding: 3rem;
      cursor: pointer;

      svg {
        fill: var(--colorHEX13);
      }

      &:hover {
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
      }
    }

    &__sort-dropdown {
      position: relative;
      width: 165rem;
      margin: 0 10rem;
      padding: 4rem 12rem;
      background: var(--colorHEX5);
      border: 1rem solid var(--colorHEX13);
      border-radius: 4rem;

      color: rgba(255, 255, 255, 0.5);
      font-weight: 550;
      font-size: 14rem;
      line-height: 20rem;

      display: flex;
      align-items: center;
      text-align: center;
      cursor: default;

      svg {
        position: absolute;
        right: 12rem;
        margin-left: 4rem;
        fill: rgba(255, 255, 255, 0.3);
      }

      &:hover {
        color: rgba(255, 255, 255, 0.5);
        background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);

        svg {
          fill: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  &__accordion-list {
    height: calc(100vh - 437rem);
    overflow: auto;
  }

  &__accordion {
    &__header {
      display: flex;
      align-items: center;
      padding: 8rem 36rem;
      cursor: pointer;

      &:hover {
        background: rgba(var(--colorRGB13), 0.2);
      }

      svg {
        fill: var(--colorHEX13)

      }

      &.active {
        background: rgba(var(--colorRGB13), 0.1);

        &:hover {
          background: rgba(var(--colorRGB13), 0.2);
        }

        svg {
          fill: var(--colorHEX13)
        }
      }

      &__title {
        margin: 0rem 12rem;
        font-weight: 700;
        font-size: 15rem;
        line-height: 20rem;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
        linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));

        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;

        &.active {
          background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
          linear-gradient(0deg, var(--colorHEX14), var(--colorHEX14));
          background-size: 100%;
          background-repeat: repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-background-clip: text;
          -moz-text-fill-color: transparent;
        }
      }

      &__icon-second {
        transition: all 0.4s ease-in-out;
        transform: rotate(0deg);
        margin-left: auto;

        &.active {
          transform: rotate(180deg);
        }
      }
    }

    &__body {
      background: rgba(var(--colorRGB13), 0.1);
    }
  }

  &__hr-line {
    height: 1rem;
    background: var(--colorHEX13);
  }

  &__content {
    overflow: auto;
    height: calc(100vh - 363rem);
  }

  // card
  &__card {

    &__wrapper {
      display: flex;
      align-items: center;
      padding: 12rem 36rem;

      &:hover,
      &--hovered {
        background: rgba(var(--colorRGB13), 0.2);
        cursor: pointer;

        .n-links-menu__card__content__iconsgroup {
          width: 115rem;
          padding-right: 20rem;
          flex-wrap: nowrap;
          flex-shrink: 0;
          opacity: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 15rem;
          height: auto;

          & > * {
            width: 30rem;
          }
        }
      }
    }

    &__image {
      img {
        border: 1rem solid var(--colorHEX13);
        border-radius: 8rem;
      }
    }

    &__content {
      padding: 0rem 16rem;
      width: auto;

      &__title {
        font-weight: 700;
        font-size: 15rem;
        line-height: 18rem;
        color: #FEFEFE;
      }

      &__descriptions {
        margin-top: 6rem;
        height: 40rem;
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
        overflow: auto;
        display: flex;

        ul{
          padding-left: 20rem;
        }
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4rem;
        height: 48rem;
        width: 48rem;
        background-color: transparent;

        &:hover {
          background-color: rgba(255, 255, 255, 0.25);
        }
      }

      &__iconsgroup {
        overflow: hidden;
        width: 0;
        flex-wrap: nowrap;
        transition: all .4s;
        opacity: 0;
        flex-shrink: 0;
        height: 40rem;

        & > * {
          width: 0;
          transition: all .4s;
        }
      }
    }

    &__info {
      margin-left: auto;
      margin-top: 6rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 20rem;
      white-space: nowrap;

      &__icon-list {
        display: flex;
        align-items: center;
        text-align: center;

        &__icon {
          margin-left: 4rem;
          font-weight: 700;
          font-size: 11rem;
          line-height: 13rem;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);

          svg {
            fill: var(--colorHEX13);
            margin: 4rem;
          }
        }
      }

      &__author {
        margin-top: 6rem;
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
      }
    }

    &__btns {
      display: none;
      margin-left: auto;

      &__wrapper {
        display: flex;

        .button-xs {
          &:last-child {
            margin-left: 8rem;
          }
        }
      }
    }

    &__border {
      margin: 0 36rem 0 36rem;
      border-bottom: 1rem solid var(--colorHEX13);
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
      opacity: 0.2;
    }
  }
}
