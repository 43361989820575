.modal-progress-bar {
  background: none!important;
  .modal-content {
    background-color: rgba(255, 255, 255, 0)!important;
    box-shadow: 5rem 6rem 20rem 0rem rgba(0, 0, 0, 0) !important;
  }
  .loader {
    color: #000000;
    font-size: 20rem;
    margin: 100rem auto;
    width: 50rem;
    height: 50rem;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    border: 2rem solid #f3f3f31e; /* Light grey */
    border-top: 2rem solid #1E70CD; /* Blue */
    border-radius: 50%;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
}