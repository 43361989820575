// TODO: Правильно разнести стили

.n-widget {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 300rem;
    height: inherit;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
        ),
        var(--colorHEX11);
    border: 1rem solid var(--colorHEX8);
    border-radius: 6rem;

    // New widget header
    &-header {
        flex: 0 0 auto;
        height: 36rem;
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.1),
                rgba(255, 255, 255, 0.1)
            ),
            var(--colorHEX11);
        border-radius: 6rem 6rem 0rem 0rem;
        border-bottom: 1rem solid var(--colorHEX8);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6rem 8rem;
        z-index: 99;
        &__close {
            width: 24rem;
            height: 24rem;
            cursor: pointer;
            svg {
                width: 24rem;
                height: 24rem;
                padding: 4rem;
                fill: var(--colorHEX7) !important;
            }
            :hover {
                // background: linear-gradient(0deg, #ffffff, #ffffff);
                border-radius: 32rem;
            }
        }
        &__unread{
          background-color: #FF0000;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            font-weight: 900;
            font-size: 14px;
            height: 22px;
            width: 22px;
            border-radius: 100%;
        }
        &__title {
            font-weight: 500;
            font-size: 17rem;
            line-height: 25rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--colorHEX7);
            cursor: pointer;
        }
        &__share {
            width: 24rem;
            height: 24rem;
            cursor: pointer;
            &:hover {
                width: 24rem;
                height: 24rem;
                background: rgba(255, 255, 255, 0.35);
                border-radius: 32rem;
            }
            svg {
                width: 24rem;
                height: 24rem;
                padding: 4rem;
                fill: var(--colorHEX7) !important;
            }
        }
        &__menu {
            width: 24rem;
            height: 24rem;
            cursor: pointer;
            &:hover {
                width: 24rem;
                height: 24rem;
                background: rgba(255, 255, 255, 0.35);
                border-radius: 32rem;
            }
            svg {
                width: 24rem;
                height: 24rem;
                padding: 4rem;
                fill: var(--colorHEX7) !important;
            }
        }
        &__back {
            width: 24rem;
            height: 24rem;
            font-size: 15rem;
            line-height: 25rem;
            display: flex;
            align-items: center;
            text-align: center;
            color: var(--colorHEX7);
            cursor: pointer;
        }
    }
    // New widget body
    // &-filters,
    &-body {
        position: relative;
        padding: 0 4rem 0 6rem;
        flex: 1 1 auto;
        min-height: 1rem;
        overflow-y: auto;

        // -ms-overflow-style: none;
        // scrollbar-color: transparent var(--colorHEX7);
        // &--hidden {
        //     display: none;
        // }

    }
    &-filters {
        position: relative;
        height: 100%;
        overflow: auto;
        // background: linear-gradient(0deg, rgba(var(--colorRGB9), 0.3), rgba(var(--colorRGB9), 0.3)), #ffffff;
        // padding: 0 4rem 0 6rem;
        // flex: 1 1 auto;
        // min-height: 1rem;
        // overflow-y: auto;
        // // padding: 0 4rem 0 6rem;
        // flex: 1 1 auto;
        // min-height: 1rem;
        // overflow-y: auto;
        // background: linear-gradient(0deg, rgba(var(--colorRGB9), 0.3), rgba(var(--colorRGB9), 0.3)), #ffffff;
        // // background: rgba(255, 255, 255, 0.4);
        // border-radius: 0 0 6rem 6rem;
        &__bottom {
            background: rgba(255, 255, 255, 0.4);
            height: 20rem;
            border-radius: 0 0 6rem 6rem;
        }
    }

    // New widget footer
    &-footer {
        border-radius: 0rem 0rem 6rem 6rem;
        border-top: 1rem solid var(--colorHEX8);
        &__message-input {
            display: none;
            &__wrapper {
                position: relative;
            }
        }
        &__bottom {
            height: 26rem;
        }
    }
}

.extra .n-widget-body {
    position: relative;
    padding: 0;
    flex: 1 1 auto;
    min-height: 1rem;
    overflow-y: auto;
    border-radius: 0 0 6rem 6rem;
    iframe {
        position: absolute;
        height: 100%!important;
        width: 100%!important;
      }
      article {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
    }
}
.infinite-scroll-component {
    overflow-x: hidden !important;
}

.infinite-scroll-component__outerdiv {
    padding-right: 6rem;
}

.n-widget-filter-active {
    .n-widget-footer {
      display: none;
    }
    .n-widget-header__back {
      cursor: default;
    }
    .n-widget-header__menu {
      width: 24rem;
      height: 24rem;
      border-radius: 32rem;
      background:  var(--colorHEX7);
      cursor: pointer;
      &:hover {
        width: 24rem;
        height: 24rem;
        background: rgba(255, 255, 255, 0.35);
        border-radius: 32rem;
    }
      svg {
          width: 24rem;
          height: 24rem;
          padding: 4rem;
          fill: #FFFFFF !important;
      }
    }
  }

.n-widget, .n-widget-filter-active {
    background: linear-gradient(0deg, rgba(var(--colorRGB9), 0.3), rgba(var(--colorRGB9), 0.3)), #ffffff;
}
.scale-container{
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 98;
    bottom: 80px;
    left: 20px;

    button{
        height: 22px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px!important;
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}