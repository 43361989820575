.el-sort {
  width: var(--el-sort-size, 45px);
  height: var(--el-sort-size, 45px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: revert;
  box-shadow: var(--input-box-shadow-default);
  padding: 0;
  border-radius: var(--border-radius-default);
  cursor: pointer;
  transition: background-color var(--transition-default);
  user-select: none;
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
  @include hover-active;
}
