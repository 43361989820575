// Profile space

.el-settings-lines {
  // max-width: 450px;
}

.el-settings-line {
  padding: 12px;
  display: grid;
  grid-template-rows: auto auto;
  gap: 8px;
  position: relative;
  min-height: 50px;

  &.hoverable {
    cursor: pointer;
    border-radius: var(--border-radius-default);
    overflow: hidden;

    & > * {
      z-index: 1;
    }
  }

  &.active {
    box-shadow: 0px 2px 4px 0px #00000040 inset;
    border: 1px solid #ffffff14;
    background: linear-gradient(0deg, #203b60, #203b60),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08),
        rgba(255, 255, 255, 0.08)
      );
  }

  &.hoverable:after {
    content: "";
    opacity: 0;
    transition: opacity var(--transition-quick);
    background-color: white;
    position: absolute;
    inset: 0;
    z-index: 0;
  }

  &.hoverable {
    &:hover {
      &:after {
        opacity: 0.1;
      }

      .el-settings-line-right {
        opacity: 1;
      }
    }
  }

  &.locked {
    cursor: default;

    .el-settings-line-right {
      opacity: 1;
    }
  }

  &.oneLine {
    grid-template-rows: auto;
  }
}

.el-settings-line-right {
  opacity: 0;
  transition: opacity var(--transition-quick);
  height: 100%;
  display: flex;
  align-items: center;

  .el-button.themeOutline {
    height: 32px;
    padding: 0 8px;
  }
}

.el-settings-line-left {
  svg {
    margin-top: -2px;
  }
}

.el-settings-line-center {
  width: 100%;
}

.el-profile-description {
  color: var(--text-color);
}

.el-complete-copytrading-account-wrapper {
  color: var(--text-color);

  &.empty > .el-flex,
  &.process > div > .el-flex,
  &.error > div > .el-flex > .el-flex,
  &.success > div > .el-flex > .el-flex {
    max-width: 320px;
    text-align: center;
    margin: 0 auto;
  }

  .el-flex {
    align-items: center;
  }

  .el-input-wrapper {
    max-width: 320px;

    input {
      max-width: inherit;
    }
  }
}

.el-profile-right-block-title {
  text-transform: none;
}

.el-account-subscription-row {
}
.el-account-subscription-row-title {
  flex: 1 0 230px;
}
.el-account-subscription-row-name {
  overflow: hidden;
  flex: 0 0 140px;
}

.el-account-subscription-row-date {
  flex: 0 0 70px;
  overflow: hidden;
}

.el-account-subscription-row-status {
  flex: 0 0 80px;
  overflow: hidden;
}

.el-account-subscription-row-action-wrapper {
  overflow: hidden;
  flex: 0 0 130px;
  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
}

.el-button.el-account-subscription-row-action {
  min-width: 130px;
  width: 130px;
  height: 30px;
  max-height: 30px;
  min-height: 30px;
  font-size: 13px;
  padding: 0;
  .spinner-border {
    width: 17.5rem;
    height: 17.5rem;
    margin-right: 10rem;
  }
}

*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.el-create-program-linking-screen {
  button.themePrimary {
    max-width: 320px;
  }

  .channels-list-wrapper {
    --max-height-linked-list: 200px;
    max-height: var(--max-height-linked-list);
  }

  .channels-list-border {
    position: relative;
    max-height: var(--max-height-linked-list);
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 6px;
      left: 0;
      height: 1px;
      background-color: rgba(78, 141, 232, 0.3);
    }
  }
}

.el-store-create-banner {
  border: 1px dashed rgba(66, 121, 198, 1);
  border-radius: var(--border-radius-default);
  margin-bottom: 12px;
  button.themePrimary {
    width: 296px;
  }

  .el-store-create-program-banner > * {
    max-width: 296px;
  }
}

.el-account-subscription-row-linked-program {
  position: relative;
  &:before {
    content: "";
  }
}

.el-delete-warning-block {
  background-color: rgba(32, 59, 96, 1);
  min-width: 694px;
  padding: 16px;
}
