.sidenav {
  display: flex;

  .ps__thumb-y,
  .ps__rail-y {
    width: .125rem !important;
  }

  .ps__rail-y {
    right: .25rem !important;
    left: auto !important;
    background: none !important;

    @include rtl-style {
      right: auto !important;
      left: .25rem !important;
    }
  }

  .ps__rail-y:hover,
  .ps__rail-y:focus,
  .ps__rail-y.ps--clicking,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking > .ps__thumb-y {
    width: .375rem !important;
  }

  .app-brand {
    width: 100%;
  }
}

.sidenav-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}

.sidenav-item,
.sidenav-header,
.sidenav-divider,
.sidenav-block {
  flex: 0 0 auto;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidenav-item {
  align-items: flex-start;
  justify-content: flex-start;

  &.sidenav-item-animating {
    transition: height $sidenav-animation-duration ease-in-out;
  }
}

.sidenav-item .sidenav-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;

  .sidenav-item.active > & {
    font-weight: $font-weight-semibold;
  }

  .sidenav-item.disabled & {
    cursor: default !important;
  }

  .sidenav:not(.sidenav-no-animation) & {
    transition-duration: $sidenav-animation-duration;
    transition-property: color, background-color;
  }

   > :not(.sidenav-icon) {
    flex: 0 1 auto;
  }
}

.sidenav-toggle::after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: $caret-width;
  height: $caret-width;
  border: 1px solid;
  border-top: 0;
  border-right: 0;
  transform: translateY(-50%) rotate(45deg);

  .sidenav-item.open:not(.sidenav-item-closing) > & {
    transform: translateY(-50%) rotate(-45deg);
  }

  .sidenav:not(.sidenav-no-animation) & {
    transition-duration: $sidenav-animation-duration;
    transition-property: -webkit-transform, transform;
  }
}

.sidenav-menu {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;

  .sidenav:not(.sidenav-no-animation) & {
    transition: background-color $sidenav-animation-duration;
  }

  .sidenav-item.open > & {
    display: flex;
  }
}

.sidenav-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: $sidenav-icon-expanded-spacer;
  font-size: $sidenav-icon-expanded-font-size;

  @include rtl-style {
    margin-right: 0;
    margin-left: $sidenav-icon-expanded-spacer;
  }
}

.sidenav-divider {
  width: 100%;
  border: 0;
  border-top: 1px solid;
}

// *******************************************************************************
// * Vertical

.sidenav-vertical {
  overflow: hidden;
  flex-direction: column;

  &:not(.sidenav-no-animation) {
    transition: width $sidenav-animation-duration;
  }

  &,
  .sidenav-block,
  .sidenav-inner > .sidenav-item,
  .sidenav-inner > .sidenav-header {
    width: $sidenav-width;
  }

  .sidenav-inner {
    flex-direction: column;
    flex: 1 1 auto;

     > .sidenav-item {
      margin: $sidenav-item-spacer 0;
    }
  }

  .sidenav-item .sidenav-link,
  .sidenav-header,
  .sidenav-block {
    padding: $sidenav-vertical-link-padding-y $sidenav-vertical-link-padding-x;
  }

  .sidenav-divider {
    margin-top: $sidenav-vertical-link-padding-y;
    margin-bottom: $sidenav-vertical-link-padding-y;
    padding: 0;
  }

  .sidenav-item .sidenav-toggle {
    padding-right: calc(#{$sidenav-vertical-link-padding-x} + #{$caret-width * 3});

    @include rtl-style {
      padding-right: $sidenav-vertical-link-padding-x;
      padding-left: calc(#{$sidenav-vertical-link-padding-x} + #{$caret-width * 3});
    }

    &::after {
      right: $sidenav-vertical-link-padding-x;

      @include rtl-style {
        right: auto;
        left: $sidenav-vertical-link-padding-x;
      }
    }
  }

  .sidenav-menu {
    padding-top: $sidenav-vertical-menu-link-padding-y;
    padding-bottom: $sidenav-vertical-menu-link-padding-y;

    .sidenav-link {
      padding-top: $sidenav-vertical-menu-link-padding-y;
      padding-bottom: $sidenav-vertical-menu-link-padding-y;
    }
  }

  .sidenav-icon {
    width: $sidenav-icon-expanded-width;
  }

  .sidenav-menu .sidenav-icon {
    margin-right: 0;

    @include rtl-style {
      margin-left: 0;
    }
  }

  // Levels
  //

  $sidenav-first-level-spacer: $sidenav-vertical-link-padding-x + $sidenav-icon-expanded-width + $sidenav-icon-expanded-spacer;

  .sidenav-menu .sidenav-link,
  .sidenav-menu .sidenav-header {
    padding-left: $sidenav-first-level-spacer;

    @include rtl-style {
      padding-right: $sidenav-first-level-spacer;
      padding-left: $sidenav-vertical-link-padding-x;
    }
  }

  @for $i from 2 through $sidenav-max-levels {
    $selector: '';

    @for $l from 1 through $i {
      $selector: "#{$selector} .sidenav-menu";
    }

    #{$selector} .sidenav-link,
    #{$selector} .sidenav-header {
      padding-left: $sidenav-first-level-spacer + ($sidenav-vertical-menu-level-spacer * ($i - 1));

      @include rtl-style {
        padding-right: $sidenav-first-level-spacer + ($sidenav-vertical-menu-level-spacer * ($i - 1));
        padding-left: $sidenav-vertical-link-padding-x;
      }
    }
  }
}


// *******************************************************************************
// * Horizontal

.sidenav-horizontal {
  flex-direction: row;
  width: 100%;

  .sidenav-inner {
    overflow: hidden;
    flex-direction: row;
    flex: 0 1 100%;
  }

  .sidenav-item .sidenav-link {
    padding: $sidenav-horizontal-link-padding-y $sidenav-horizontal-link-padding-x;
  }

  .sidenav-item .sidenav-toggle {
    padding-right: calc(#{$sidenav-horizontal-link-padding-x} + #{$caret-width * 3});

    @include rtl-style {
      padding-right: $sidenav-horizontal-link-padding-x;
      padding-left: calc(#{$sidenav-horizontal-link-padding-x} + #{$caret-width * 3});
    }

    &::after {
      right: $sidenav-horizontal-link-padding-x;

      @include rtl-style {
        right: auto;
        left: $sidenav-horizontal-link-padding-x;
      }

    }
  }

  .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
    transform: translateY(-50%) rotate(-45deg);

    @include rtl-style {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .sidenav-inner > .sidenav-item:not(.sidenav-item-closing).open > .sidenav-toggle::after {
    transform: translateY(-50%) rotate(135deg);

    @include rtl-style {
      transform: translateY(-50%) rotate(-135deg);
    }
  }

  .sidenav-inner > .sidenav-header,
  .sidenav-inner > .sidenav-divider {
    display: none !important;
  }

  .sidenav-menu {
    position: absolute;
    width: $sidenav-menu-width;

    .sidenav-menu {
      position: static;
      width: auto;
    }

    .sidenav-link {
      padding-top: $sidenav-horizontal-menu-link-padding-y;
      padding-bottom: $sidenav-horizontal-menu-link-padding-y;
    }

    .sidenav-header {
      padding: $sidenav-horizontal-menu-link-padding-y $sidenav-horizontal-link-padding-x;
    }
  }

  .sidenav-inner > .sidenav-item > .sidenav-menu {
    @include border-bottom-radius($border-radius);
  }

  &:not(.sidenav-no-animation) .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    animation: sidenavDropdownShow $sidenav-animation-duration ease-in-out;
  }

  // Levels
  @for $i from 2 through $sidenav-max-levels {
    $selector: '';

    @for $l from 1 through $i {
      $selector: "#{$selector} .sidenav-menu";
    }

    #{$selector} .sidenav-link,
    #{$selector} .sidenav-header {
      padding-left: $sidenav-horizontal-menu-level-spacer * $i;

      @include rtl-style {
        padding-right: $sidenav-horizontal-menu-level-spacer * $i;
        padding-left: $sidenav-horizontal-link-padding-x;
      }
    }
  }
}

.sidenav-horizontal-wrapper {
  overflow: hidden;
  flex: 0 1 100%;
  width: 0;

  .sidenav:not(.sidenav-no-animation) & .sidenav-inner {
    transition: margin $sidenav-animation-duration;
  }
}

.sidenav-horizontal-prev,
.sidenav-horizontal-next {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: $sidenav-control-width;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: $sidenav-control-arrow-size;
    height: $sidenav-control-arrow-size;
    border: 1px solid;
    border-top: 0;
  }

  &.disabled {
    cursor: default !important;
  }
}

.sidenav-horizontal-prev::after {
  border-right: 0;
  transform: translate(-50%, -50%) rotate(45deg);

  @include rtl-style {
    transform: translate(-50%, -50%) rotate(-135deg);
  }
}

.sidenav-horizontal-next::after {
  border-left: 0;
  transform: translate(-50%, -50%) rotate(-45deg);

  @include rtl-style {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

@include keyframes(sidenavDropdownShow) {
  0% {
    opacity: 0;
    transform: translateY(-.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// *******************************************************************************
// * Universal coloring

.sidenav-dark {
  color: $navbar-dark-color;

  .sidenav-link,
  .sidenav-horizontal-prev,
  .sidenav-horizontal-next {
    color: $navbar-dark-color;

    @include hover-focus {
      color: $navbar-dark-hover-color;
    }

    &.active {
      color: $navbar-dark-active-color;
    }
  }

  .sidenav-item.disabled .sidenav-link {
    color: $navbar-dark-disabled-color !important;
  }

  .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav-item.active > .sidenav-link {
    color: $navbar-dark-active-color;
  }

  .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background: $sidenav-dark-menu-bg;
  }

  .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    color: $navbar-dark-color;
  }

  .sidenav-text {
    color: $navbar-dark-active-color;
  }

  .sidenav-header {
    color: $navbar-dark-color;
  }

  hr,
  .sidenav-divider,
  .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: $sidenav-dark-border-color !important;
  }

  .sidenav-inner > .sidenav-header::before,
  .sidenav-block::before {
    background-color: $navbar-dark-disabled-color;
  }

  .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: $sidenav-dark-border-color;
  }

  .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: $navbar-dark-active-color;
  }

  .ps__thumb-y {
    background: $navbar-dark-color !important;
  }
}

.sidenav-light {
  color: $navbar-light-color;

  .sidenav-link,
  .sidenav-horizontal-prev,
  .sidenav-horizontal-next {
    color: $navbar-light-color;

    @include hover-focus {
      color: $navbar-light-hover-color;
    }

    &.active {
      color: $navbar-light-active-color;
    }
  }

  .sidenav-item.disabled .sidenav-link {
    color: $navbar-light-disabled-color !important;
  }

  .sidenav-item.open:not(.sidenav-item-closing) > .sidenav-toggle,
  .sidenav-item.active > .sidenav-link {
    color: $navbar-light-active-color;
  }

  .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background: $sidenav-light-menu-bg;
  }

  .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-menu,
  .sidenav-inner > .sidenav-item.sidenav-item-closing .sidenav-item.open .sidenav-toggle {
    color: $navbar-light-color;
  }

  .sidenav-text {
    color: $navbar-light-active-color;
  }

  .sidenav-header {
    color: $navbar-light-color;
  }

  hr,
  .sidenav-divider,
  .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    border-color: $sidenav-light-border-color !important;
  }

  .sidenav-inner > .sidenav-header::before,
  .sidenav-block::before {
    background-color: $navbar-light-disabled-color;
  }

  .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before {
    background-color: $sidenav-light-border-color;
  }

  .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    background-color: $navbar-light-active-color;
  }

  .ps__thumb-y {
    background: $navbar-light-color !important;
  }
}

// *******************************************************************************
// * Collapsed

@mixin sidenav-collapsed() {
  width: $sidenav-collapsed-width;

  .sidenav-inner > .sidenav-item {
    width: $sidenav-width + ($sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width);
  }

  .sidenav-inner > .sidenav-item > .sidenav-link {
    padding-left: $sidenav-collapsed-width;
  }

  .sidenav-inner > .sidenav-header,
  .sidenav-block {
    position: relative;
    margin-left: $sidenav-collapsed-width;
    padding-right: ($sidenav-vertical-link-padding-x * 2) - $sidenav-icon-expanded-spacer;
    padding-left: $sidenav-icon-expanded-spacer;
    width: $sidenav-width;

    &::before {
      content: "";
      position: absolute;
      top: $sidenav-vertical-link-padding-y;
      bottom: $sidenav-vertical-link-padding-y;
      left: -1 * ($sidenav-collapsed-width * .75);
      display: block;
      width: $sidenav-collapsed-width / 2;
    }
  }

  .sidenav-menu .sidenav-header,
  .sidenav-menu .sidenav-divider {
    visibility: hidden !important;
  }

  .sidenav-inner > .sidenav-item > .sidenav-menu,
  .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    position: relative;
    margin-left: $sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width;
    background: none !important;

    .sidenav-link {
      background: none !important;
      transition: none !important;
    }
  }

  .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(#{$sidenav-collapsed-width / 2} - 1px);
      display: block;
      margin-left: -($sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width);
      width: 0;
      border-left: 2px solid;
    }
  }

  .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle,
  .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      margin-top: -4px;
      margin-left: calc(#{($sidenav-collapsed-width / 2) - ($sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width)} - 4px);
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
    right: auto;
    left: $sidenav-collapsed-width - $sidenav-control-arrow-size - .375rem;
  }

  .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
    margin-left: -$sidenav-collapsed-width;
    width: $sidenav-collapsed-width;
    text-align: center;
  }
}

@mixin sidenav-collapsed-rtl() {
  .sidenav-inner > .sidenav-item > .sidenav-link {
    padding-right: $sidenav-collapsed-width;
    padding-left: $sidenav-vertical-link-padding-x;
  }

  .sidenav-inner > .sidenav-header,
  .sidenav-block {
    margin-right: $sidenav-collapsed-width;
    margin-left: 0;
    padding-right: $sidenav-icon-expanded-spacer;
    padding-left: ($sidenav-vertical-link-padding-x * 2) - $sidenav-icon-expanded-spacer;

    &::before {
      right: -1 * ($sidenav-collapsed-width * .75);
      left: auto;
    }
  }

  .sidenav-inner > .sidenav-item > .sidenav-menu,
  .sidenav-inner > .sidenav-item.open > .sidenav-menu {
    margin-right: $sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width;
    margin-left: 0;
  }

  .sidenav-inner > .sidenav-item.open > .sidenav-menu::before {
    right: calc(#{$sidenav-collapsed-width / 2} - 1px);
    left: auto;
    margin-right: -($sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width);
    margin-left: 0;
  }

  .sidenav-inner > .sidenav-item.open .sidenav-item.open > .sidenav-toggle::before,
  .sidenav-inner > .sidenav-item.open .sidenav-item.active > .sidenav-link::before {
    right: 0;
    left: auto;
    margin-right: calc(#{($sidenav-collapsed-width / 2) - ($sidenav-collapsed-width - $sidenav-vertical-link-padding-x - $sidenav-icon-expanded-width)} - 4px);
    margin-left: 0;
  }

  .sidenav-inner > .sidenav-item > .sidenav-toggle::after {
    right: $sidenav-collapsed-width - $sidenav-control-arrow-size - .375rem;
    left: auto;
  }

  .sidenav-inner > .sidenav-item > .sidenav-link .sidenav-icon {
    margin-right: -$sidenav-collapsed-width;
    margin-left: $sidenav-icon-expanded-spacer;
  }
}

.sidenav-collapsed:not(:hover) {
  @include sidenav-collapsed();

  @include rtl-style {
    @include sidenav-collapsed-rtl();
  }
}

.light-style .sidenav .app-brand.demo {
  height: 64px;
}

.dark-style .sidenav .app-brand.demo {
  height: 64px;
}

.material-style .sidenav .app-brand.demo {
  height: 70px;
}

.app-brand-logo.demo {
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.app-brand-logo.demo-2 {
  // height: 30px;
  width: 100%;
}
.app-brand-logo.demo svg {
  width: 20px;
  height: 11px;
}

.app-brand-text.demo {
  font-size: 1.1rem;
}

.layout-1 .layout-sidenav .app-brand.demo,
.layout-1 .layout-sidenav .app-brand.demo + .sidenav-divider,
.layout-reversed .layout-2 .layout-sidenav .app-brand.demo {
  display: none;
}

.layout-1 .layout-navbar .app-brand.demo,
.layout-reversed .layout-2 .layout-navbar .app-brand.demo {
  display: -ms-flexbox !important;
  display: flex !important;
}

.layout-1 .layout-navbar .layout-sidenav-toggle,
.layout-reversed .layout-2 .layout-navbar .layout-sidenav-toggle,
.layout-offcanvas .layout-navbar .layout-sidenav-toggle,
.layout-fixed-offcanvas .layout-navbar .layout-sidenav-toggle {
  display: block !important;
}

@media (max-width: 991px) {
  .layout-offcanvas .layout-sidenav .layout-sidenav-toggle,
  .layout-fixed-offcanvas .layout-sidenav .layout-sidenav-toggle {
    display: none;
  }
}

/* *****************************************************************************
 * Navbar
 */

.demo-navbar-messages .dropdown-toggle,
.demo-navbar-notifications .dropdown-toggle,
.demo-navbar-user .dropdown-toggle,
.demo-navbar-messages.b-nav-dropdown .nav-link,
.demo-navbar-notifications.b-nav-dropdown .nav-link,
.demo-navbar-user.b-nav-dropdown .nav-link {
  white-space: nowrap;
}

.demo-navbar-messages .dropdown-menu,
.demo-navbar-notifications .dropdown-menu {
  overflow: hidden;
  padding: 0;
}

@media (max-width: 991px) {
  .demo-navbar-messages .dropdown-menu,
  .demo-navbar-notifications .dropdown-menu {
    margin-top: .5rem;
    width: 22rem;
  }

  .demo-navbar-user .dropdown-menu {
    margin-top: .25rem;
  }
}


/* *****************************************************************************
 * Content
 */

.demo-vertical-spacing > * + * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-sm > * + * {
  margin-top: .9375rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg > * + * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-inline-spacing > *,
.demo-paragraph-spacing > p > * {
  margin: 0 .375rem .9375rem 0 !important;
}

.demo-paragraph-spacing > p {
  margin: 0;
}

.demo-blocks > * {
  display: block !important;
}

html:not(.material-style) .material-style-only {
  display: none !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir=rtl] .rtl-only {
  display: block !important;
}

/* *****************************************************************************
 * Layouts
 */

.layout-example-block {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid rgba(0,0,0,.04);
  border-radius: 2px;
  background: rgba(0,0,0,.04);
  color: #c7254e;
}
.dark-style .layout-example-block {
  border-color: rgba(255,255,255,.2);
  background: rgba(255,255,255,.1);
}
.layout-example-block code {
  background: none;
  color: #666;
}
.dark-style .layout-example-block code {
  color: #fff;
}

.layout-example-block-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: 500px;
}

.layout-example-block-sidenav {
  width: 24%;
  background: #607d8b !important;
}
.layout-example-block-sidenav code {
  color: #fff;
}

.layout-example-block-navbar {
  background: #3ca99e !important;
}
.layout-example-block-navbar code {
  color: #fff;
}

.layout-example-block-content {
  background: #9e9e9e !important;
}
.layout-example-block-content code {
  color: #fff;
}

.layout-example-block-1 .layout-example-block-content {
  width: 74%;
}

.layout-example-block-2 .layout-example-block-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 74%;
}
.layout-example-block-2 > .layout-example-block {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.layout-example-block-2 .layout-example-block-content {
  -ms-flex-positive: 1;
      flex-grow: 1;
}

.layout-example-block-1-no-sidenav .layout-example-block-content {
  width: 100%;
}

.layout-example-code {
  display: block;
  overflow: auto;
  margin: 0;
  padding: 30px;
  min-width: 0;
  width: 100%;
  background: #fff;
}

.dark-style .layout-example-code {
  background: #2f3238;
}

.layout-example-run {
  display: inline-block;
  margin: 3px;
  padding: 4px 6px;
  border: 1px solid #eee;
  background: transparent;
  white-space: nowrap;
  line-height: 1;
}

.dark-style .layout-example-run {
  border-color: rgba(255, 255, 255, .2);
}

.layout-example-block-3 .layout-example-block-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.layout-example-block-3 .layout-example-block-inner > .layout-example-block {
  -ms-flex: 0 0 auto;
      flex: 0 0 auto;
}
.layout-example-block-3 .layout-example-block-inner .layout-example-block-container-fluid {
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.layout-example-block-3 .layout-example-block-sidenav-horizontal {
  background: #607d8b !important;
}
.layout-example-block-3 .layout-example-block-container-fluid {
  background: #fff !important;
}
.layout-example-block-3 .layout-example-block-container-fluid code {
  color: #666 !important;
}
.layout-example-block-3 .layout-example-block-footer {
  background: #e6514a !important;
}

.dark-style .layout-example-block-content .layout-example-block:not(.layout-example-block-sidenav-horizontal) code {
  color: #666 !important;
}
@media (max-width: 991px) {
.layout-fixed .layout-sidenav, .layout-fixed-offcanvas .layout-sidenav {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
}