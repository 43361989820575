// Core variables and mixins
@import "./abstracts/variables";

// Mixins
@import "./mixins/css3-mixins";
@import "./mixins/mixins-button";
@import "./mixins/scrollbars";

// New styles
@import "./n-account-menu.scss";
@import "./n-button.scss";
@import "./n-channels-menu.scss";
@import "./n-checkbox-switch.scss";
@import "./n-checkbox.scss";
@import "./n-extras-menu.scss";
@import "./n-feature-plan.scss";
@import "./n-guide.scss";
@import "./n-header.scss";
@import "./n-input.scss";
@import "./n-label.scss";
@import "./n-links-menu.scss";
@import "./n-modal.scss";
@import "./n-packages-menu.scss";
@import "./n-local-channels.scss";
@import "./n-profile-settings-menu.scss";
@import "./n-public-channels.scss";
@import "./n-radio.scss";
@import "./n-settings-menu.scss";
@import "./n-stripe-card.scss";
@import "./n-template.scss";
@import "./n-textarea.scss";
@import "./n-vendors-menu.scss";
@import "./n-workspace.scss";
@import "./n-copytrading-menu.scss";
@import "./react-dreaft-wysiwyg-override";
@import "./bootstrap-overrides";
@import "./n-custom-column-filter.scss";
@import "./n-copytrading-table.scss";

//             //
//  NEW Widget //
//             //
@import "./n-widget.scss";
@import "./n-message.scss";


.sketch-picker {
  border-radius: 10px!important;
}

// NEW EL-DESIGN
@import "./el-design/style";