.n-local-channels {
    &__search {
        padding: 32rem 36rem 16rem 36rem;
        position: relative;
        input[type=text] {
          width: 100%;
          background: var(--colorHEX5);
          border: 1rem solid var(--colorHEX13);
          box-sizing: border-box;
          border-radius: 5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12rem 12rem 12rem 18rem;
          font-size: 16rem;
          line-height: 19rem;
          color: #FFFFFF;
          &:placeholder-shown + .n-local-channels__search__reset {
            opacity: 0;
            pointer-events: none;
          }
          &::placeholder {
            font-size: 16rem;
            line-height: 19rem;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 33%, rgba(255, 255, 255, 0.5) 66%, rgba(255, 255, 255, 0.5));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: var(--colorHEX7);
          }
          &:hover {
            background: linear-gradient(0deg, rgba(var(--colorRGB13), 0.3), rgba(var(--colorRGB13), 0.3)), var(--colorHEX5);
            color: #FFFFFF;
            &::placeholder {
              color: #FFFFFF;
            }
          }
          &:focus {
            background: var(--colorHEX4);
            color: #FFFFFF;
          }
        }
        &__reset {
          // cursor: pointer;
          position: absolute;
          border: none;
          display: block;
          width: 15rem;
          height: 15rem;
          line-height: 16rem;
          font-size: 12rem;
          border-radius: 50%;
          top: 15rem;
          bottom: 0;
          right: 51rem;
          margin: auto;
          // background: #ddd;
          padding: 0;
          outline: none;
          cursor: pointer;
          transition: .1s;
          svg {
            top: 0rem !important;
            bottom: 0 !important;
            right: 0rem !important;
            fill: var(--colorHEX10);
            stroke: var(--colorHEX10) !important;
          }
        }
        svg {
          position: absolute;
          right: 50rem;
          top: 46rem;
          pointer-events: none;
          stroke: var(--colorHEX7);
        }
      }
    &__nav {
        display: flex;
        // justify-content :flex-start;
        padding: 16rem 36rem 16rem 36rem;
        border-bottom: 1rem solid var(--colorHEX13);
        &__sort-btn {
            width: 30rem;
            height: 30rem;
            background: var(--colorHEX5);
            border: 1rem solid var(--colorHEX13);
            border-radius: 4rem;
            padding: 3rem;
            cursor: pointer;
            svg {
                fill: var(--colorHEX13);
            }
            &:hover {
                background: linear-gradient(
                        0deg,
                        rgba(var(--colorRGB13), 0.3),
                        rgba(var(--colorRGB13), 0.3)
                    ),
                    var(--colorHEX5);
            }
        }
        &__sort-dropdown {
            margin: 0 10rem;
            padding: 0 12rem;
            color: var(--colorHEX13);
            background: var(--colorHEX5);
            border: 1rem solid var(--colorHEX13);
            border-radius: 4rem;

            font-size: 14rem;
            line-height: 21rem;

            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;
            svg {
                margin-left: 4rem;
                fill: var(--colorHEX13);
            }
            &:hover {
                color: #ffffff;
                background: linear-gradient(
                        0deg,
                        rgba(var(--colorRGB13), 0.3),
                        rgba(var(--colorRGB13), 0.3)
                    ),
                    var(--colorHEX5);
                svg {
                    fill: #ffffff;
                }
            }
        }
        &__create {
            margin-left: auto;
            // width: 159rem!important;
        }
    }
    &__body {
    }
    &__card-list {
        height: calc(100vh - 439rem);
        overflow: auto;
    }
    &__card {
        padding: 16rem 36rem 0rem 36rem;
        &:hover {
            background: rgba(var(--colorRGB13), 0.2);
            svg {
                fill: rgba(255, 255, 255, 0.5);
            }
            .n-local-channels__card__btns {
                display: block;
            }
        }
        &__wrapper {
            display: flex;
        }
        &__icon {
            svg {
                fill: var(--colorHEX13);
            }
        }
        &__info {
            padding-left: 10rem;
            align-items: center;
            // &:hover {
            //   display: none;
            // }
            &__title {
                font-weight: 700;
                font-size: 15rem;
                line-height: 20rem;
                color: #fefefe;
            }
            &__description {
                margin-top: 6rem;
                font-size: 11rem;
                line-height: 13rem;
                color: #bebebe;
            }
        }
        &__btns {
            display: none;
            margin-left: auto;
            margin-top: 5rem;
            &__wrapper {
                display: flex;
                div {
                    margin-left: 8rem;
                }
            }
        }
        &__border {
            margin-top: 16rem;
            border-bottom: 1rem solid var(--colorHEX13);
            -webkit-background-clip: padding-box; /* for Safari */
            background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
            opacity: 0.2;
        }
    }
}
