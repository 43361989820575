.el-unfold {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: opacity var(--transition-default), var(--transition-default);
  animation-fill-mode: forwards;
  animation-duration: var(--animation-default);
  animation-delay: var(--animation-default);
  & > div {
    transition: var(--transition-default) opacity var(--transition-default);
    min-height: 0;
    opacity: 0;
  }
  &.visible {
    grid-template-rows: 1fr;
    animation-name: unfold-fade-in;
    & > div {
      opacity: 1;
    }
  }
}

@keyframes unfold-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
