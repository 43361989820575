.n-stripe-card {
  padding: 0rem 36rem 40rem 36rem;
  &__content {
    border: 1rem solid var(--colorHEX13);
    box-sizing: border-box;
    border-radius: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    display: flex;
    padding: 14rem 24rem;
    font-weight: 500;
    font-size: 18rem;
    line-height: 21rem;
    color: #FFFFFF;
    &__card {
      margin-left: 15rem;
    }
  }
  &__input {
    .StripeElement {
      width: 379rem;
    }
  }
  &__btn {
    padding-right: 24rem;
  }
}
.n-stripe-notification {
  padding: 0rem 36rem 40rem 36rem;
  display: flex;
  align-items: center;
  text-align: center;
  &__title {
    font-size: 24rem;
    line-height: 28rem;
    letter-spacing: 0.01em;
    background-image: linear-gradient(
0deg
, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(
0deg
, var(--colorHEX13), var(--colorHEX13));
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    margin: 0 28rem;
    align-items: center;
    text-align: center;
  }
}