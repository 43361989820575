.n-workspace-nav {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60rem;
  background-color: var(--colorHEX9);

  &__container {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
          180deg,
          rgba(17, 17, 17, 0.2) 0%,
          rgba(17, 17, 17, 0) 100%
      );
  }

  &__wrapper {
    display: flex;
    align-items: center;
    height: 60rem;
    padding: 11rem 40rem 10rem 32rem;
    .n-button-workspace {
      margin-right: 8rem;
    }
  }
}

.admin-form-image img {
  max-width: 100%;
}