
.authentication-form {
  flex-shrink: 1;
}
.authentication-form__image {
  align-items: center;
  width: 300px;
  height: 100%;
}

.authentication-form__logo-name {
  padding-top: 60px;
  color: #000000;
  width: 400px;
  font-size: 24px;
  font-weight: 200;
  word-wrap: break-word;
}

.authentication-form__logo-description {
  padding-top: 15px;
  color: #000000;
  width: 400px;
  font-size: 20px;
  font-weight: 200;
  word-wrap: break-word;
}

.authentication-form__privacy-policy {
  padding-top: 60px;
  color: #000000;
  width: 400px;
  font-size: 18px;
  font-weight: 300;
  word-wrap: break-word;
  a { 
    color: #000000;
    text-decoration: none;
   }
   a:hover {
    color: #000000;
    text-decoration: underline;
   }
}

.authentication-form__bottom-logo {
  padding-top: 60px;
  font-weight: 200;
  font-size: 12px;
}
.authentication-form__bottom-description {
  & a>img {
    width: 64px;
    height: 100%;
  }
}

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: initial;
  border: initial;
  background-color: transparent
}

:root, [data-amplify-theme] {
  --amplify-components-heading-color: rgb(78, 81, 85);
  --amplify-colors-teal-100: rgb(38, 179, 255);
  --amplify-colors-teal-90: rgba(38, 179, 255, 0.9);
  --amplify-colors-teal-80: rgba(38, 179, 255, 0.8);
  --amplify-colors-teal-60: rgba(38, 179, 255, 0.6);
  --amplify-colors-teal-40: rgba(38, 179, 255, 0.4);
  --amplify-colors-teal-20: rgba(38, 179, 255, 0.2);
  --amplify-colors-teal-10: rgba(38, 179, 255, 0.1);
}

[data-indicator-position=top] > .amplify-tabs-item {
  background-color: var(--amplify-components-tabs-item-background-color);
  border-width: 0 0 var(--amplify-components-tabs-border-width) 0;
}

.amplify-tabs[data-indicator-position=top] {
  border: 0

}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0 20px 20px 20px;
}

.amplify-tabs-item {
  font-weight: 500
}

[data-amplify-authenticator] [data-amplify-container] {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0
}

[data-amplify-router] div[role="tabpanel"],
[data-amplify-form][data-amplify-authenticator-resetpassword],
[data-amplify-form][data-amplify-authenticator-confirmresetpassword],
[data-amplify-form][data-amplify-authenticator-confirmsignup] {
  padding: 20px 40px
}

.amplify-field-group {
  background-color: white
}