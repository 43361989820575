.n-profile-settings-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  right: 0;
  background-color: var(--colorHEX6);
  transition: 0.5s;
  height: calc(100vh - 120rem);
  top: 60rem;
  &__header {
    padding: 44rem 36rem;
    &__text {
      font-weight: 700;
      font-size: 36rem;
      line-height: 44rem;
      background-image: linear-gradient(
        0deg
        , rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(
        0deg
        , var(--colorHEX13), var(--colorHEX13));
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
      text-align: right;
      cursor: default;
    }
  }
  &__content {
    overflow: auto;
    height: calc(100vh - 252rem);
    &__title {
      font-weight: 700;
      font-size: 21rem;
      line-height: 29rem;
      letter-spacing: 0.01em;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
                        linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      padding: 0 36rem 8rem 36rem;
    }
  }
  &__section {
    padding-bottom: 45rem;
    &__title {
      font-weight: 700;
      font-size: 21rem;
      line-height: 29rem;
      letter-spacing: 0.01em;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      padding: 0 36rem 8rem 36rem;
      cursor: default;
    }
    &__card {
      &:hover {
        background: rgba(var(--colorRGB13), 0.2);
        .n-profile-settings-menu__section__card__btn, .n-profile-settings-menu__section__card__btn-r {
          display: block;
        }
      }
      &__content {
        padding: 16rem 36rem;
        display: flex;
        flex-direction: column;
      }
      &__body {
        display: flex;
        position: relative;
      }
      &__footer {
        margin: 6rem 30rem 0 30rem;
      }
      &__title {
        margin: 0rem 10rem;
        font-weight: 700;
        font-size: 15rem;
        // line-height: 20rem;
        color: #FEFEFE;
        cursor: default;
      }
      &__subtitle {
        font-size: 11rem;
        line-height: 13rem;
        color: #BEBEBE;
        cursor: default;
      }
      &__icon {
        svg {
          fill: var(--colorHEX13);
        }
      }
      &__icon-block {
        margin-left: auto;
        svg {
          fill: var(--colorHEX13);
        }
      }
      &__btn {
        display: none;
        position: absolute;
        top: 6rem;
        right: 0;
      }
      &__btn-r {
        display: none;
        position: absolute;
        top: -3rem;
        right: 0; 
      }
      &__border {
        margin: 0 36rem;
        border-bottom: 1px solid var(--colorHEX13);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        opacity: 0.2;
      }
    }
  }
  &__nav {
    margin-left: 36rem;
    width: 139rem;
  }
}

.tour-active {
  .n-profile-settings-menu__section__card__btn {
    display: block;
  }
}
