.el-scrollable {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &.el-scrollable-hidden {
    overflow: hidden;
  }
  &.el-scrollable-stable-gutter {
    scrollbar-gutter: stable;
  }
  &.el-scrollable-auto {
    overflow: auto;
  }
  &.el-scrollable-overlay {
    overflow: overlay;
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }
    z-index: 1;
    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
  &.el-scrollable-full {
    width: 100%;
    height: 100%;
  }
  &.el-scrollable-hidden-x {
    overflow-x: hidden;
  }
  &.el-scrollable-hidden-y {
    overflow-y: hidden;
  }
}
