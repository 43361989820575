.n-settings-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  right: 0;
  background-color: var(--colorHEX6);
  transition: 0.5s;
  height: calc(100vh - 120rem);
  top: 60rem;
  &__header {
    padding: 44rem 36rem;
    &__text {
      font-weight: 700;
      font-size: 36rem;
      line-height: 44rem;
      background-image: linear-gradient(
        0deg
        , rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(
        0deg
        , var(--colorHEX13), var(--colorHEX13));
            background-size: 100%;
            background-repeat: repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
      text-align: right;
      cursor: default;
    }
  }
  &__content {
    overflow: auto;
    height: calc(100vh - 252rem);
    &__section {
      // color: blanchedalmond;
      padding-bottom: 0;
      &:not(:last-child) {
        padding-bottom: 64rem;
      }
      &__title {
        font-weight: 700;
        font-size: 21rem;
        line-height: 29rem;
        letter-spacing: 0.01em;
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
                          linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
        background-size: 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        padding: 0 36rem 8rem 36rem;

      }
    }
  }
}


.n-naccordion__content, .n-naccordion__title {
  display: inline-block;
}

.n-naccordion__content {
  background: rgba(var(--colorRGB13), 0.1);
  padding: 12rem 36rem 24rem 72rem
}

.n-naccordion {
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 8rem 36rem;
    cursor: pointer;
    svg {
      fill: var(--colorHEX13);
    }
    &:hover {
      background: rgba(var(--colorRGB13), 0.2);
    }
    &__name {
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
                        linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      font-weight: 600;
      font-size: 15rem;
      line-height: 20rem;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      svg {
        margin-right: 12rem;
      }
    }
    &__icon {
      transition: transform 0.3s ease-in-out;
    }
  }
}

.active .n-naccordion__title__icon {
  transform: rotate(180deg);
}

.active .n-naccordion__title {
  background: rgba(var(--colorRGB13), 0.1);

}

.folding-pannel {
  display: block;
  margin-top: 1rem;
  transition: all 0.2s ease-in;
}

.active .n-naccordion__title {
  border-color: transparent;
  transition: border-color 0.2s ease-out;
}

.inactive .folding-pannel {
  // transform-origin: 50% 0%;
  // transform: perspective(250rem) rotateX(-90deg);
  // transition: all 0.4s ease-in-out;
  height: 0;
  // padding: 0 0 15rem 0;
  padding: 0 0 0 72rem;
  overflow: hidden;

}
.active .folding-pannel {
  // transform: perspective(350rem) rotateX(0deg);
  // transition: all 0.4s ease-in-out;
  overflow: visible;
}
