//             //
//  NEW Widget Message Item //
//             //

.message-item {
    background: rgba(255, 255, 255, 0.77);
    border: 1rem solid var(--colorHEX8) !important;
    @include box-shadow(0rem, 1rem, 2rem, rgba(0, 0, 0, 0.12));

    &-header {
        position: relative;
        padding: 8rem 16rem;
        display: flex;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        -ms-flex-pack: justify;
        flex-direction: column;
        justify-content: space-between;

        &__user-block {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-align: center;
            align-items: center;
            &__avatar {
                width: 32rem !important;
                height: 32rem !important;
                border: 1rem solid rgba(var(--colorRGB2), 0.3);
                border-radius: 100rem;
                margin-right: 8rem;
                flex-shrink: 1;

                font-weight: 550;
                font-size: 15rem;
                line-height: 18rem;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: -0.065em;
                text-transform: uppercase;
            }
            &__info {
                display: flex;
                flex-direction: column;
                &__name {
                    font-weight: 500;
                    font-size: 13rem;
                    line-height: 19rem;
                    color: var(--colorHEX5);
                }
                &__time-link {
                    display: flex;
                    font-size: 11rem;
                    line-height: 16rem;
                    color: var(--colorHEX10);
                    span {
                        cursor: pointer;
                        text-decoration-line: underline;
                    }
                }
                &__submenu {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    &__time {
                        font-size: 11rem;
                        line-height: 16rem;
                        color: var(--colorHEX10);
                    }
                    &__btns {
                        display: flex;
                        justify-content: flex-end;
                    }
                }
            }
        }
        &__subblock {
            display: none;
            margin: 10rem 0 0 40rem;
            &__time {
                font-size: 11rem;
                line-height: 16rem;
                color: var(--colorHEX10);
            }
            &__nav {
                display: flex;
                justify-content: flex-end;
                margin-top: 12rem;
                .button-outlone-xm {
                    margin: 0 5rem;
                }
            }
        }
        &__controls {
            position: absolute;
            right: 6rem;
            top: 6rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: center;
            opacity: 1;
            &__btn-label {
                width: 24rem;
                height: 24rem;
                margin-bottom: 10rem;
                cursor: pointer;
                svg {
                    fill: var(--colorHEX7);
                }
            }
            &__btn-submenu {
                width: 24rem;
                height: 24rem;
                cursor: pointer;
                transition: transform 0.5s ease;
                svg {
                    fill: var(--colorHEX7);
                }
            }
        }
    }
    &-body {
        width: 100%;
        padding: 8rem 16rem 20rem 16rem;
        font-size: 15rem;
        line-height: 22rem;
        color: var(--colorHEX5);
            flex: unset!important;
            width: 100%!important;

        &>div{
           &>div{
               width: 100%!important;
           }
        }
    }
}

.active-subblock {
    background: linear-gradient(
            0deg,
            rgba(var(--colorRGB12), 0.1),
            rgba(var(--colorRGB12), 0.1)
        ),
        #ffffff;
    border-bottom: 1rem solid var(--colorHEX8);
    border-radius: 6rem 6rem 0 0;
}

.subblock-active {
    display: block;
}

// ACTIVE Цвет текста и иконок активного окна
.is-active-item .widget-body {
    background: linear-gradient(
            0deg,
            rgba(var(--colorRGB9), 0.3),
            rgba(var(--colorRGB9), 0.3)
        ),
        #ffffff;
}
.is-active-item {
    @include box-shadow(0rem, 8rem, 32rem, rgba(21, 38, 62, 0.8));

    .active-subblock {
        border-bottom: 1rem solid rgba(var(--colorRGB7), 0.5);
    }
    .n-widget {
        &-header {
            background: linear-gradient(
                    0deg,
                    rgba(var(--colorRGB9), 0.3),
                    rgba(var(--colorRGB9), 0.3)
                ),
                linear-gradient(180deg, #ededed 0%, #d3d3d3 100%);
            background-blend-mode: color, normal;
            border-radius: 6rem 6rem 0rem 0rem;
            border-bottom: 1rem solid #ffffff !important;
        }
        &-body {
            // #3764A4 30%
            background: linear-gradient(
                    0deg,
                    rgba(var(--colorRGB9), 0.3),
                    rgba(var(--colorRGB9), 0.3)
                ),
                #ffffff;
        }
        &-footer {
            background: linear-gradient(
                    0deg,
                    rgba(var(--colorRGB10), 0.45),
                    rgba(var(--colorRGB10), 0.45)
                ),
                #ffffff;
            mix-blend-mode: normal;
            border-top: 1rem solid #ffffff;
            &__message-input {
                display: block;
                flex-direction: column;
                &__wrapper {
                    padding: 12rem 26rem 12rem 26rem;
                }
                &__nav {
                    display: flex;
                    justify-content: space-between;
                    width: 164rem;
                    &__riche-msg {
                        cursor: pointer;
                        svg {
                            fill: var(--colorHEX7) !important;
                        }
                    }
                    &__icons {
                        cursor: pointer;
                        svg {
                            fill: var(--colorHEX7) !important;
                        }
                    }
                    &__file > input {
                        display: none;
                    }
                    &__rss {
                        width: 24rem;
                        cursor: pointer;
                        svg {
                            fill: var(--colorHEX7) !important;
                        }
                    }
                    &__file {
                        width: 24rem;
                        cursor: pointer;
                        svg {
                            fill: var(--colorHEX7) !important;
                        }
                    }
                }
            }
            &__bottom {
                display: none;
            }
        }
    }
    .message-item {
        background: #ffffff;
        border: 1rem solid rgba(var(--colorRGB7), 0.5) !important;
        &-header {
            &--controls {
                opacity: 0.5 !important;
            }
            &__user-blok {
                &__info {
                    &__name {
                        color: var(--colorHEX2);
                    }
                }
            }
        }
    }
    .active-subblock > .message-item-header--controls {
        opacity: 1 !important;
    }
}

.is-out {
    margin: 4rem 0 4rem 12rem;
    border-radius: 6rem 6rem 0rem 6rem !important;
    &:first-child {
        margin-bottom: 16rem;
    }
}

.is-in {
    margin: 4rem 12rem 4rem 0;
    border-radius: 6rem 6rem 6rem 0rem !important;
    &:first-child {
        margin-bottom: 16rem;
    }
}

.rotate {
    background: #ffffff;
    border-radius: 28rem;
    transform: rotate(90deg);
    padding: 0.5rem 1rem 2rem 3rem;
}

.message-title-table {
    font-size: 18rem;
    line-height: 26rem;
    font-weight: 500;
    margin-bottom: 8rem;
}


.message-item-reply {
    position: relative;
    svg {
        fill: var(--colorHEX7);
    }
    &__icon {
        position: absolute;
        left: -9rem;
        top: -12rem;
    }
}

.message-item .message-replay  {
    border: none!important;
    border-left: 2rem solid var(--colorHEX8)!important;
}

.is-in .message-replay  {
    margin: 0 0 10rem 0!important;
    border-radius: 0!important;
    box-shadow: none!important;
}

.is-out .message-replay  {
    margin: 0 0 10rem 0!important;
    border-radius: 0!important;
    box-shadow: none!important;
}


.count-msg-length {
    font-weight: 500;
    margin: -5rem 0 5rem 0;
    font-size: 12rem;
    color: var(--colorHEX10);
    text-align: right;
}

.xlsx-wrapper object{
    width: 100%!important;
}