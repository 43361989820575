////
.n-checkbox-filter-default-1  {
  display: block;
  position: relative;
  padding-left: 28rem;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 500;
  font-size: 13rem;
  line-height: 19rem;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--colorHEX2);

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18rem;
    width: 18rem;
    border: 2rem solid var(--colorHEX8);
    border-radius: 2rem;
  }

  .checkmark-all {
    position: absolute;
    top: 0;
    left: 0;
    height: 18rem;
    width: 18rem;
    border: 2rem solid var(--colorHEX8);
    background-color: var(--colorHEX8);
    border-radius: 2rem;
    z-index: 1;
  }
}
.n-checkbox-filter-default-1  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.n-checkbox-filter-default-1 :hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0);
}
.n-checkbox-filter-default-1  input:checked ~ .checkmark {
  background-color: var(--colorHEX8);
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.n-checkbox-filter-default-1  input:checked ~ .checkmark:after {
  display: block;
}
.n-checkbox-filter-default-1  .checkmark:after {
  left: 4rem;
  top: 1rem;
  width: 6rem;
  height: 10rem;
  border: solid white;
  border-width: 0 2rem 2rem 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}


//

.n-checkbox-filter-default-1 :hover input ~ .checkmark-all {
  background-color: rgba(255, 255, 255, 0);
  z-index: 1;
}
.checkmark-all:after {
  content: "";
  position: absolute;
  z-index: 1;
}
.n-checkbox-filter-default-1  .checkmark-all:after {
  left: 2rem;
  top: 6rem;
  width: 10rem;
  border: solid white;
  border-width: 0 2rem 2rem 0;
  z-index: 1;
}


////

.n-checkbox-filter-default-2 {
  display: block;
  position: relative;
  padding-left: 25rem;
  margin-bottom: 16rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14rem;
  color: #FFFFFF;
  cursor: pointer;
  &:last-child {
    margin-bottom: 6rem;
  }
.checkmark {
  position: absolute;
  top: 2rem;
  left: 0;
  height: 18rem;
  width: 18rem;
  border: 2rem solid #FFFFFF;
  border-radius: 2rem;
  opacity: 0.5;
}
}
.n-checkbox-filter-default-2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.n-checkbox-filter-default-2:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0);
}
.n-checkbox-filter-default-2 input:checked ~ .checkmark {
  border: 2rem solid white;
  background-color: rgba(53, 95, 154, 0);
  opacity: 1;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.n-checkbox-filter-default-2 input:checked ~ .checkmark:after {
  display: block;
}
.n-checkbox-filter-default-2 .checkmark:after {
  left: 4rem;
  top: 1rem;
  width: 6rem;
  height: 10rem;
  border: solid rgb(255, 255, 255);
  border-width: 0 2rem 2rem 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
// .n-checkbox-sources {
// input:indeterminate
// }
