// -------------------------------------------------------------------------------
// Theme mixin

@mixin platform-common-theme($background, $color: null) {
  @include platform-text-emphasis-variant('.text-primary', $background);
  @include bg-variant('.bg-primary', $background);
  html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary { border-color: $background !important; }
  @include platform-badge-variant('.badge-primary', $background, $color);
  @include platform-badge-outline-variant('.badge-outline-primary', $background);
  @include platform-pagination-theme($background, $color);
  @include platform-progress-bar-theme($background, $color);
  @include platform-list-group-theme($background, $color);
  @include platform-alert-variant('.alert-primary', $background);
  @include platform-alert-dark-variant('.alert-dark-primary', $background, $color);
  @include platform-callout-variant('.callout-primary', $background);
  @include platform-tooltip-variant('.tooltip-primary, .tooltip-primary > .tooltip, .ngb-tooltip-primary + ngb-tooltip-window', $background, $color);
  @include platform-popover-variant('.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window', $background, $color);
  @include platform-table-row-variant('.table-primary', $background);
  @include platform-button-variant('.btn-primary', $background, $color);
  @include platform-button-outline-variant('.btn-outline-primary', $background, $color);
  @include platform-dropdown-theme($background, $color);
  @include platform-nav-theme($background, $color);
  @include platform-custom-control-theme($background, $color);
  @include platform-custom-file-input-theme($background);
  @include platform-form-control-theme($background);
  @include platform-switcher-theme($background, $color);
  @include platform-navbar-variant('.navbar.bg-primary', $primary-color);
  @include platform-sidenav-variant('.sidenav.bg-primary', $primary-color);
  @include platform-footer-variant('.footer.bg-primary', $primary-color);
  @include platform-toast-theme('.bg-primary', $primary-color, $color);
  .fill-primary { fill: $background }
}

@mixin platform-common-material-theme($background, $color: null) {
  @include platform-text-emphasis-variant('.text-primary', $background);
  @include bg-variant('.bg-primary', $background);
  html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary { border-color: $background !important; }
  @include platform-badge-variant('.badge-primary', $background, $color);
  @include platform-badge-outline-variant('.badge-outline-primary', $background);
  @include platform-pagination-theme($background, $color);
  @include platform-progress-bar-theme($background, $color);
  @include platform-list-group-theme($background, $color);
  @include platform-alert-variant('.alert-primary', $background);
  @include platform-alert-dark-variant('.alert-dark-primary', $background, $color);
  @include platform-callout-variant('.callout-primary', $background);
  @include platform-tooltip-variant('.tooltip-primary, .tooltip-primary > .tooltip, .ngb-tooltip-primary + ngb-tooltip-window', $background, $color);
  @include platform-popover-variant('.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window', $background, $color);
  @include platform-table-row-variant('.table-primary', $background);
  @include platform-material-button-variant('.btn-primary', $background, $color);
  @include platform-material-button-outline-variant('.btn-outline-primary', $background, $color);
  @include platform-material-dropdown-theme($background, $color);
  @include platform-material-nav-theme($background, $color);
  @include platform-material-custom-control-theme($background, $color);
  @include platform-material-custom-file-input-theme($background);
  @include platform-material-form-control-theme($background);
  @include platform-material-switcher-theme($background);
  @include platform-navbar-variant('.navbar.bg-primary', $primary-color);
  @include platform-sidenav-variant('.sidenav.bg-primary', $primary-color);
  @include platform-footer-variant('.footer.bg-primary', $primary-color);
  @include platform-toast-theme('.bg-primary', $primary-color, $color);
  .fill-primary { fill: $background }
}

@mixin platform-common-dark-theme($background, $color: null) {
  @include platform-text-emphasis-variant('.text-primary', $background);
  @include bg-variant('.bg-primary', $background);
  html:not([dir=rtl]) .border-primary, html[dir=rtl] .border-primary { border-color: $background !important; }
  @include platform-badge-variant('.badge-primary', $background, $color);
  @include platform-badge-outline-variant('.badge-outline-primary', $background);
  @include platform-pagination-theme($background, $color);
  @include platform-progress-bar-theme($background, $color);
  @include platform-list-group-theme($background, $color);
  @include platform-alert-variant('.alert-primary', $background);
  @include platform-alert-dark-variant('.alert-dark-primary', $background, $color);
  @include platform-callout-variant('.callout-primary', $background);
  @include platform-tooltip-variant('.tooltip-primary, .tooltip-primary > .tooltip, .ngb-tooltip-primary + ngb-tooltip-window', $background, $color);
  @include platform-popover-variant('.popover-primary, .popover-primary > .popover, .ngb-popover-primary + ngb-popover-window', $background, $color);
  @include platform-table-row-variant('.table-primary', $background);
  @include platform-button-variant('.btn-primary', $background, $color);
  @include platform-button-outline-variant('.btn-outline-primary', $background, $color);
  @include platform-dropdown-theme($background, $color);
  @include platform-nav-theme($background, $color);
  @include platform-custom-control-theme($background, $color);
  @include platform-custom-file-input-theme($background);
  @include platform-form-control-theme($background);
  @include platform-switcher-theme($background, $color);
  @include platform-navbar-variant('.navbar.bg-primary', $primary-color);
  @include platform-sidenav-variant('.sidenav.bg-primary', $primary-color);
  @include platform-footer-variant('.footer.bg-primary', $primary-color);
  @include platform-toast-theme('.bg-primary', $primary-color, $color);
  .fill-primary { fill: $background }
}
