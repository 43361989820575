.n-account-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  right: 0;
  background: rgba(var(--colorRGB5), 1);
  transition: 0.5s;
  height: calc(100vh - 120rem);
  top: 60rem;

  &__header {
    padding: 44rem 36rem 30rem 36rem;

    &__text {
      font-weight: 700;
      font-size: 36rem;
      line-height: 44rem;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: right;
      cursor: default;
    }
  }

  &__body {
    background: linear-gradient(
                    180deg, rgba(var(--colorRGB13), 0.2) 0%, rgba(var(--colorRGB13), 0) 100%);
  }

  &__section {
    overflow: auto;
    height: calc(100vh - 400rem);

    &__title {
      padding: 44rem 36rem 24rem 36rem;
      font-weight: 700;
      font-size: 21rem;
      line-height: 29rem;
      letter-spacing: 0.01em;
      background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
      linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
      background-size: 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__payment {
    height: 117rem;
  }
}

.n-table {
  &__header {
    font-size: 14rem;
    line-height: 21rem;
    display: flex;
    align-items: center;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    linear-gradient(0deg, var(--colorHEX13), var(--colorHEX13));
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1rem solid var(--colorHEX13);

    &__item-1 {
      margin: 10rem 6rem 10rem 36rem;
      width: 312rem;
      text-align: left;
    }

    &__item-2 {
      margin: 10rem 6rem;
      width: 120rem;
      text-align: left;
    }

    &__item-3 {
      margin: 10rem 6rem;
      width: 90rem;
      text-align: left;
    }

    &__item-4 {
      margin: 10rem 6rem;
      width: 110rem;
      text-align: right;
    }
  }

  &__row {
    // display: -webkit-box;
    // display: -moz-box;
    // display: flex-box;
    // display: -webkit-flex;
    // display: -moz-flex;
    // display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -moz-flex-flow: row nowrap;
    flex-flow: row nowrap;

    &__start {
      text-align: left;
    }

    &__end {
      text-align: right;
    }

    &__items {
      display: flex;
      align-items: center;

      &:hover {
        background: rgba(var(--colorRGB13), 0.2);

        .n-table__row__item-3__btn {
          display: block;
        }

        .n-table__row__item-3__status {
          display: none;
        }

        .n-table__row__item-3 {
          margin: 0 6rem;
        }
      }

      &__border {
        border-bottom: 1rem solid var(--colorHEX13);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        opacity: 0.2;
      }
    }

    &__item-1 {
      margin: 10rem 6rem 10rem 36rem;
      width: 312rem;

      font-weight: 700;
      font-size: 15rem;
      line-height: 18rem;
      color: #FEFEFE;
      cursor: default;
    }

    &__item-2 {
      margin: 10rem 6rem;
      width: 120rem;

      font-weight: 700;
      font-size: 11rem;
      line-height: 13rem;
      display: flex;
      align-items: center;
      color: rgba(255, 255, 255, 0.8);
      cursor: default;
    }

    &__item-3 {
      margin: 10rem 6rem;
      width: 90rem;
      display: flex;

      &--small-text {
        font-size: 11rem;
        font-weight: 700;
      }

      &__status {
        display: flex;
        align-items: center;

        &__title {
          margin-left: 4rem;
          font-weight: 700;
          font-size: 11rem;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(255, 255, 255, 0.8);
        }
      }

      &__btn {
        display: none;

        div {
          padding: 1.5rem 12rem;
          line-height: 20rem;
        }
      }
    }

    &__item-4 {
      margin: 10rem 6rem;
      width: 110rem;

      font-weight: 700;
      font-size: 11rem;
      line-height: 13rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: rgba(255, 255, 255, 0.8);
      cursor: default;
    }
  }
}
