input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.el-input-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: inherit;

  &.left {
    input,
    textarea {
      padding-left: var(--input-padding-left);
    }
  }

  &.right {
    input,
    textarea {
      padding-right: var(--input-padding-left);
    }
  }

  &.notEmpty {
    input,
    textarea {
      background-color: #ffffff1a;
    }
  }

  &.error {
    input,
    textarea {
      --input-box-shadow: inset 0px 0px 0px 1px var(--red-color);
      border-width: 0 !important;
    }
    .texareaResizer {
      --input-border-color: var(--red-color);
    }
  }

  &.success {
    input,
    textarea {
      --input-box-shadow: inset 0px 0px 0px 1px var(--green-color);
    }
  }
}

.el-input {
  padding: var(--input-padding);
  // border: 1px solid var(--input-border-color);
  border: 0;
  box-shadow: var(--input-box-shadow, var(--input-box-shadow-default));
  background-color: transparent;
  color: var(--text-color);
  width: inherit;
  height: inherit;
  font-size: inherit;
  line-height: 22px;
  border-radius: var(--border-radius-default);
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.el-input-left-wrapper,
.el-input-right-wrapper {
  top: 0;
  display: flex;
  height: 100%;
  width: 44px;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.el-input-left-wrapper {
  left: 0;
}

.el-input-right-wrapper {
  right: 0;
}

.el-textarea-div-hidden {
  padding: var(--input-padding);
  width: inherit;
  height: inherit;
  font-size: 15px;
  line-height: 22px;
  inset: 0;
  position: absolute;
  visibility: hidden;

  &.right {
    padding-left: var(--input-padding-left);
  }

  &.left {
    padding-right: var(--input-padding-right);
  }
}

.el-textarea-wrapper {
  textarea {
    height: var(--textarea-height, 46px);
    &::-webkit-resizer {
      visibility: hidden;
    }
  }

  .resizeNone textarea {
    resize: none;
  }
  .resizeNone .texareaResizer {
    display: none;
  }

  .texareaResizer {
    position: absolute;
    pointer-events: none;
    bottom: 5px;
    right: 0;
  }
}

.el-input-file {
  &.hidden {
    position: absolute;
    width: 0;
    height: 0;
    display: none;
  }
}
