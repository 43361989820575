.disclamer-modal {
  .n-modal__header__title-red {
    text-align: center;
  }

  .n-modal__wrapper {
    text-align: left;
  }

  .n-modal__header {
    margin-top: 40rem;
  }
}