.el-chat-list-wrapper {
  display: flex;
  flex-direction: column-reverse;
  .infinite-scroll-component__outerdiv {
    padding: 0;
  }
  .infinite-scroll-component {
    scrollbar-gutter: stable;
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
    height: 100%;
    width: 100%;
    overflow: overlay;
    position: relative;
    opacity: 1;
    transition: var(--transition-default) opacity;
    padding: var(--gradient-height, 16px) 0 var(--gradient-height, 16px) 0;
  }
  &.widgetTheme {
    padding: 0 12px 18px;
    flex-direction: column-reverse;
    .infinite-scroll-component {
      gap: 12px;
    }
    .el-chat-message-wrapper {
      max-width: 80%;
      &:after {
        // background-color: rgba(0, 0, 0, 0.3);
      }
      &.withoutBackgroundFill.fromMe {
        background-color: #2c4f82;
      }
    }
    & + .el-chat-list-loading-view {
      --background-color: var(--wwidget-background-color);
    }
    .chat-list-form__text-container__data {
      word-wrap: break-word;
    }
  }
  span,
  p {
    color: var(--chat-message-body-color, var(--text-color));
  }
  .btnShowMore span {
    color: #387cdf;
  }
}

.el-chat-message-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  color: #ffffff0d;
  padding: 8px 12px;
  margin-top: var(--chat-message-margin-top, 0px);
  margin-right: auto;
  width: auto;
  max-width: calc(
    min(
      var(--chat-message-max-width-px),
      var(--chat-message-max-width-percent, 80%)
    )
  );
  min-width: calc(
    min(
      var(--chat-message-min-width-px),
      var(--chat-message-min-width-percent, 90%)
    )
  );
  &.fromMe {
    margin-left: auto;
    margin-right: initial;
    &:after {
      border-radius: 6px 6px 0px 6px;
      color: var(--chat-color);
      opacity: 1;
    }
    .el-chat-message-text {
      font-weight: 500;
      color: var(--chat-message-body-color, var(--chat-message-sender-color));
    }
  }
  &.withoutBackgroundFill.fromMe {
    &:after {
      background-color: #2c4f82;
    }
  }
  &:before,
  &:after {
    z-index: 1;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 6px 6px 6px 0px;
  }
  &:before {
    opacity: 0;
    background-color: #ffffff25;
  }
  &:after {
    background-color: var(--chat-message-background, #ffffff0d);
    opacity: 1;
    box-shadow: 0px 4px 6px 0px #0000001a;
  }
  &.message-highlighted:before {
    opacity: 1;
  }
  & > * {
    z-index: 2;
  }
  .ql-editor {
    p {
      margin-bottom: 0px;
      word-wrap: break-word;
    }
  }
}

.el-chat-message-creator {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.el-chat-message-divider {
  display: inline-block;
  padding: 0 8px;
  color: var(--chat-message-text-color);
}

.el-chat-message-author,
.el-chat-message-channel {
  font-family: Noto Sans Display;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  max-width: 45%;
  display: inline-flex;
  color: var(--chat-message-body-color, var(--text-color));
}

.el-chat-message-author {
  color: var(--message-author-color, var(--chat-message-author-color));
}

.el-chat-message-channel {
  color: var(--message-channel-color, var(--chat-message-channel-color));
}

.el-chat-message-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--chat-message-receiver-color);
  word-wrap: break-word;
  white-space: break-spaces;
}

.el-chat-message-time {
  // font-family: Roboto;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: right;
  color: var(--chat-message-body-color, var(--chat-message-time-color));
}

.el-chat-date-wrapper,
.el-chat-new-messages {
  width: 100%;
  text-align: center;
}

.el-chat-date-wrapper {
  margin: 20px 0;
}

.el-chat-new-messages {
  margin: 10px 0;
}

.el-chat-date-date,
.el-chat-new-messages {
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--chat-time-separator-color);
}

.el-chat-new-messages {
  color: var(--text-color);
}

.el-chat-spaces-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.el-chat-space-wrapper {
  cursor: pointer;
  padding: 6px;
  display: flex;
  gap: 12px;
  transition: background-color var(--transition-default);
  border-radius: var(--border-radius-default);
  align-items: center;
  &:hover {
    background-color: #4e8de833;
  }
}

.el-chat-space-wrapper-active > .el-chat-space-wrapper {
  background-color: #4e8de833;
}

.el-chat-space-left {
  width: auto;
}

.el-chat-space-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
}

.el-chat-space-icon {
  width: auto;
}

.el-chat-space-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--text-color);
  .ellipsed {
    width: auto;
  }
}

.el-chat-space-last-message {
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.25px;
  color: var(--chat-spaces-last-message-color);
  min-height: 22.5px;
}

.el-chat-spaces-collapsed {
  .el-content-action {
    margin-right: 24px;
    transition: margin-right var(--transition-default);
  }
  .el-content-title-wrapper {
    justify-content: center;
  }
  .el-content-title {
    opacity: 0;
    transition: opacity var(--transition-default);
  }
  .el-scrollable.el-scrollable-overlay {
    overflow-x: hidden;
  }
}

.el-chat-spaces-right {
  position: relative;
  &:after {
    content: "";
    inset: 0;
    opacity: 0.025;
    position: absolute;
    background-color: white;
    pointer-events: none;
  }
}

.el-store-space-collapsed {
  transition: opacity var(--transition-default);
  .el-content-title-wrapper {
    min-height: 46px;
    gap: 0;
  }
  .el-content-title {
    transition: opacity var(--transition-default);
    width: 0px;
    opacity: 0;
    // position: absolute
  }
  .el-store-details-wrapper {
    opacity: 0;
  }
}

.el-chat-counter {
  height: var(--chat-counter-height);
  border-radius: calc(var(--chat-counter-height) / 2);
  background-color: var(--chat-counter-color);
  padding: 0 4px;
  // font-family: Roboto;
  font-size: 11px;
  line-height: var(--chat-counter-height);
  text-align: center;
  color: var(--text-color);
  letter-spacing: 0.1px;
  &.absolute {
    position: absolute;
    top: -4px;
    right: -4px;
  }
}

.el-chat-send-wrapper {
  width: 100%;
  min-height: var(--chat-send-min-height);
}

.el-chat-send-inner {
  display: flex;
  gap: 36px;
  width: 100%;
  height: 100%;
  margin-top: 6px;
}

.el-chat-send {
  padding: 8px 0 0 0;
}

.el-chat-send-input {
  flex: 1;
  font-size: 15px;
}

.el-chat-send-extra {
  width: auto;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 12px;
  height: 24px;
}

.el-chat-list-wrapper-inner {
  width: 100%;
  height: 100%;
  position: relative;
}

.el-chat-message-title {
  color: var(--chat-message-title-color, var(--text-color));
}

.el-chat-message-rich-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: var(--table-text-color);
  .right {
    font-weight: 500;
  }

  .left + .right + .left,
  .right + .left + .right {
    &:before {
      border-top: 1px solid var(--table-text-color);
    }
  }
  .left,
  .right {
    padding: 2px 4px;
    position: relative;
    &:before {
      content: "";
      inset: 0;
      position: absolute;
      opacity: 0.5;
    }
  }
}

.el-chat-message-title {
  font-weight: 600;
}

.el-chat-message-media {
  --max-media-size: 300px;
  max-width: var(--max-media-size);
  max-height: var(--max-media-size);
  cursor: pointer;
  color: var(--secondary-text-color);
  --far-color: var(--secondary-text-color);
  img,
  canvas {
    border-radius: var(--border-radius-default);
    width: 100%;
    height: var(--max-media-size);
    max-width: var(--max-media-size);
    max-height: var(--max-media-size);
    object-fit: cover;
  }
  .react-pdf__Page__textContent {
    display: none;
  }
}

.el-chat-scroll-to-bottom-wrapper {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 12px;
  bottom: 16px;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  visibility: visible;
  &.el-chat-scroll-to-bottom-wrapper-visible,
  &.el-chat-scroll-to-bottom-wrapper-hidden {
    animation-fill-mode: forwards;
    animation-duration: 0.24s;
  }
  &.el-chat-scroll-to-bottom-wrapper-visible {
    animation-name: scroll-to-bottom-visible;
  }
  &.el-chat-scroll-to-bottom-wrapper-hidden {
    animation-name: scroll-to-bottom-hide;
  }
}

@keyframes scroll-to-bottom-hide {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

@keyframes scroll-to-bottom-visible {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    // visibility: hidden;
    // pointer-events: none;
  }
}

.el-chat-scroll-to-bottom {
  background-color: var(--background-color);
  box-shadow: 0px 0px 0px 1px var(--text-color);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: all var(--transition-default);
  &:hover {
    filter: brightness(1.33);
  }
}

.el-chat-list-loading-view {
  position: absolute;
  inset: 0px;
  z-index: 2;
  background: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-top: -36px;
  }
}

@media (min-width: 1025px) {
  :root {
    --chat-message-min-width-px: 250px;
  }
}

@media (min-width: 1281px) {
  :root {
    --chat-message-min-width-px: 275px;
    --chat-message-max-width-percent: 75%;
  }
}

@media (min-width: 1281px) {
  :root {
    --chat-message-min-width-px: 275px;
    --chat-message-max-width-percent: 75%;
  }
}

@media (min-width: 1441px) {
  :root {
    --chat-message-min-width-px: 325px;
    --chat-message-max-width-percent: 72.5%;
  }
}

@media (min-width: 1921px) {
  :root {
    --chat-message-min-width-px: 375px;
    --chat-message-max-width-percent: 65%;
  }
}

@media (max-width: 1024px) {
  :root {
    --chat-message-min-width-px: 200px;
    --chat-message-max-width-percent: 85%;
  }
}

.el-chat-right-textarea-icon + .el-input-right-wrapper {
  align-items: flex-start;
  margin-top: 12px;
}

.media-loading-overlay {
  position: fixed;
  z-index: 2;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.66);
}
