.copytrading-expanded {
  --cell-data-color: #4b4b4b;
  tr td,
  tfoot tr th,
  thead tr th {
    border: 1px transparent;
  }
  .filled-vendors {
    font-size: 15px;
    font-weight: 600;
    background-color: var(--admin-background-brand-secondary);
    color: var(--cell-data-color);
    &:first-child {
      color: revert;
    }
  }
  .filled-masters {
    font-weight: 500;
    background-color: var(--admin-background-brand-primary);
    color: var(--cell-data-color);
    &:nth-child(2) {
      color: revert;
    }
  }
  .filled-slaves {
    background-color: #f4ebff40;
    color: var(--cell-data-color);
  }
  div.row-expansion-style {
    padding: 0;
  }
  .table.table-bordered {
    margin-bottom: 0px;
  }

  .trades-table {
    --cell-data-color: #4b4b4b;
    table {
      border-radius: 4px;
      color: var(--cell-data-color);
      thead {
        background-color: var(--admin-background-secondary);
      }
      th,
      td {
        padding: var(--admin-spacing-lg) var(--admin-spacing-3xl);
        height: 44px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: var(--admin-text-tertiary-600);
      }
      td {
        height: 72px;
        line-height: calc(72px - 2 * var(--admin-spacing-lg));
        font-size: 14px;
        span {
          line-height: inherit;
        }
      }
      tr + tr {
        border-top: 1px solid var(--admin-border-secondary);
      }
    }
  }

  .table-responsive {
    overflow-x: hidden;
  }

  tr {
    & > .filled-vendors:first-child,
    .filled-masters:nth-child(1),
    .filled-slaves:nth-child(1) {
      transition: transform 0.2s;
      position: relative;
      &:before {
        content: "";
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUgNy41TDEwIDEyLjVMMTUgNy41IiBzdHJva2U9IiM0NzU0NjciIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        width: 20px;
        height: 8px;
        display: inline-flex;
        margin-right: 12px;
        transform: rotate(270deg);
        transition: transform 0.2s, -webkit-transform 0.2s;
      }
    }
    td {
      width: 25%;
    }
    td:last-child,
    td:nth-last-child(2) {
      width: 12.5%;
    }
  }

  .expanded {
    & > .filled-vendors:first-child,
    .filled-masters:nth-child(1),
    .filled-slaves:nth-child(1) {
      &:before {
        transform: rotate(360deg);
      }
    }
  }
}

.light-style
  .react-bs-table-card
  ~ .react-bootstrap-table-pagination.react-bootstrap-table-pagination {
  border-top: 1px solid var(--admin-border-secondary);
  padding: var(--admin-spacing-lg) var(--admin-spacing-3xl);
  display: flex;
  align-items: center;
  .react-bootstrap-table-page-btns-ul {
    margin: 0px;
  }
}

.react-bootstrap-table.table-responsive.react-bs-table-card.copytrading-expanded.reports-table {
  border-radius: var(--admin-spacing-xl);
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid var(--admin-border-secondary);
  tfoot {
    background-color: var(--admin-background-secondary);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: var(--admin-text-tertiary-600);
    border-top: 1px solid var(--admin-border-secondary);
    th {
      padding: var(--admin-spacing-lg) var(--admin-spacing-3xl);
    }
  }
}
