.el-radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--el-radio-background-color);
  position: relative;
  margin: 0;
  color: var(--el-radio-color);
  width: var(--el-radio-default-width);
  height: var(--el-radio-default-height);
  border: 2px solid var(--el-radio-color);
  display: block;
  border-radius: 50%;
  cursor: pointer;
  &:before {
    content: "";
    border-radius: 50%;
    width: calc(var(--el-radio-default-width) / 2);
    height: 9px;
    transform: scale(0);
    transition: var(--transition-quick) transform ease-in-out;
    box-shadow: inset 1em 1em #f2f2f2;
    transform-origin: center;
    position: absolute;
    transform: translate(-50%, -50%) scale(0);
    left: 50%;
    top: 50%;
  }
  &:checked::before {
    transform: translate(-50%, -50%) scale(1);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:focus {
    outline: 2px solid var(--el-radio-outline-color);
    outline-offset: 2px;
  }
}
