.n-radio-button {
  label {
    display: block;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10rem;

    span {
      display: flex;
      margin-left: 10rem;
      font-size: 15rem;
      line-height: 18rem;
      letter-spacing: 0.01em;
      color: #FEFEFE;
      position: relative;

    }
  }
}