.el-flex {
  display: flex;
  gap: var(--el-flex-gap, 0);

  &.el-flex-full {
    height: 100%;
    width: 100%;
  }

  &.el-flex-full-horizonal {
    width: 100%;
  }

  &.el-flex-full-vertical {
    height: 100%;
  }

  &.el-flex-column {
    flex-direction: column;
  }

  &.el-flex-column-reverse {
    flex-direction: column-reverse;
  }

  &.el-flex-align-center {
    align-items: center;
  }

  &.el-flex-justify-center {
    justify-content: center;
  }

  &.el-flex-space-between {
    justify-content: space-between;
  }

  &.el-flex-space-around {
    justify-content: space-around;
  }

  &.el-flex-center {
    align-items: center;
    justify-content: center;
  }

  &.el-flex-align-flex-end {
    align-items: flex-end;
  }

  &.el-flex-1 {
    flex: 1;
  }

  &.el-flex-justify-flex-end {
    justify-content: flex-end;
  }
}
